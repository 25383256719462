import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';

export const notificationsRoutes: RouteRecordRaw = {
  path: '/notifications',
  name: 'Notifications',
  redirect: { name: 'NotificationsList' },
  meta: {
    layout: LayoutDefault,
    title: 'Уведомления',
    role: [roles.MarketplaceAdmin],
  },
  children: [
    {
      path: 'list',
      name: 'NotificationsList',
      component: () => import('./views/List/List.vue'),
      meta: {
        title: 'Список уведомлений',
        role: [],
      },
    },
    {
      path: 'templates',
      name: 'NotificationTemplates',
      component: () => import('./views/Templates/Templates.vue'),
      meta: {
        title: 'Шаблоны СМС',
        role: [],
      },
    },
  ],
};
