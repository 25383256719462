import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';

export const microserviceConfigsRouteNames = {
  root: {
    name: 'MicroserviceConfigs',
    title: 'Настройки микросервисов',
  },
  edit: {
    name: 'MicroserviceEdit',
    title: 'Изменение сервиса',
  },
};

export const microserviceConfigsRoutes: RouteRecordRaw = {
  path: 'ms-configs',
  name: microserviceConfigsRouteNames.root.name,
  redirect: { name: 'msRootList' },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    title: microserviceConfigsRouteNames.root.title,
    role: [roles.ConfigAdmin],
  },
  children: [
    {
      path: '',
      name: 'msRootList',
      component: () => import('./List.vue'),
      meta: {
        role: [],
      },
    },
    {
      path: ':id',
      name: microserviceConfigsRouteNames.edit.name,
      component: () => import('./Edit.vue'),
      meta: {
        title: microserviceConfigsRouteNames.edit.title,
        role: [],
      },
      props: (route) => ({ id: decodeURIComponent(route.params.id as string) }),
    },
  ],
};
