import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const tags: RouteRecordRaw = {
  path: 'tags',
  name: ROUTES.pharmacyTags.name,
  redirect: { name: 'pharmacyTagsList' },
  meta: {
    title: ROUTES.pharmacyTags.title,
    role: [roles.PharmacyAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'pharmacyTagsList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.pharmacyTagsCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.pharmacyTagsCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.pharmacyTagsEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.pharmacyTagsEdit.title,
        role: [],
      },
    },
  ],
};
