import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

import { homeCallServicesRoutes } from './views/HomeCallServices/routes';
import { polygonsRoutes } from './views/Tariffs/routes';

export const expertRoutes: RouteRecordRaw = {
  path: '/expert',
  name: ROUTES.expert.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: { name: ROUTES.expertHomeCallServices.name },
  meta: {
    layout: LayoutDefault,
    title: ROUTES.expert.title,
    role: [roles.AccountAdmin],
  },
  children: [homeCallServicesRoutes, polygonsRoutes],
};
