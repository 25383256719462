import { authorization } from '@/bootstrap';
import { jwtDecode } from '@/libs/http/jwtDecode';
import { Role } from '@/libs/user/role/roles';

export function getCurrentRole(): ReadonlyArray<Role> {
  try {
    const token = authorization.tokenStorage.get();

    const result = token ? jwtDecode(token) : null;

    return result?.realm_access?.roles || [];
  } catch (error) {
    return [];
  }
}
