<template>
  <div v-if="links.length > 0" :class="$style.navbar">
    <tabs
      v-slot="{ item }"
      as-router
      size="sm"
      type="underline"
      :items="links"
      :underline-inactive-border="false"
    >
      {{ item.content }}
    </tabs>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';

import { useFavoriteRoutes } from '@admin/shared';
import { Tabs } from '@smartmed/ui/Tabs';
import { storeToRefs } from 'pinia';

import { LOCAL_STORAGE_KEY } from '@/shared/const';

const emit = defineEmits<{ (e: 'shown', value: boolean): void }>();

const { routes } = storeToRefs(useFavoriteRoutes(LOCAL_STORAGE_KEY));

const links = computed(() => {
  return routes.value.map(({ to, name }) => ({ name: to, content: name }));
});

watch(
  links,
  (value) => {
    emit('shown', value.length > 0);
  },
  { immediate: true }
);
</script>

<style lang="scss" module>
.navbar {
  position: relative;
  display: flex;

  border-bottom: 1px solid var(--smed-base-04);
  background-color: var(--smed-base-07);
  padding-left: 1.5rem;
}
</style>
