import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';

import { LabRouteNames } from '../../routeNames';

export const invoiceToggleRoutes: RouteRecordRaw = {
  path: 'invoce-toggle',
  name: LabRouteNames.invoiceToggle.name,
  redirect: { name: 'invoiceToggleUpdate' },
  meta: {
    title: LabRouteNames.invoiceToggle.title,
    role: [roles.MarketplaceAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'invoiceToggleUpdate',
      meta: {
        role: [roles.ClinicsAdmin],
      },
      component: () => import('./Update.vue'),
    },
  ],
};
