import { WidgetGroup } from './useWidgetsPresset';

const widgetStub = {
  group: null,
  name: 'Загрузка',
  widget: {
    type: 'number_input',
    title: 'Загрузка',
  } as const,
};

export const widgetGroupLoadingStub: WidgetGroup = [
  {
    code: null,
    name: null,
    fields: new Array(3).fill('').map(() => widgetStub),
  },
];
