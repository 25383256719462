import { RouteRecordRaw } from 'vue-router';

import { activatedAttachments } from '@/application/Programs/views/ActivatedAttachments/routes';
import { errorsRoutes } from '@/application/Programs/views/Errors/routes';
import { markersRoutes } from '@/application/Programs/views/Markers/routes';
import { medicalProgramsRoutes } from '@/application/Programs/views/MedicalPrograms/routes';
import { nonActivatedAttachments } from '@/application/Programs/views/NonActivatedAttachments/routes';
import { nonMedicalProgramsRoutes } from '@/application/Programs/views/NonMedicalPrograms/routes';
import { promocodesRoutes } from '@/application/Programs/views/Promocodes/routes';
import { LayoutDefault } from '@/libs/layouts';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

import { documentsRoutes } from './views/Documents/routes';
import { principalsRoutes } from './views/Principals/routes';
import { productsRoutes } from './views/Products/routes';
import { salesRoutes } from './views/Sales/routes';

export const programsRoutes: RouteRecordRaw = {
  path: '/programs',
  name: ROUTES.programs.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.programs.title,
    role: [],
  },
  children: [
    productsRoutes,
    principalsRoutes,
    documentsRoutes,
    ...salesRoutes,
    ...markersRoutes,
    ...promocodesRoutes,
    ...medicalProgramsRoutes,
    ...nonMedicalProgramsRoutes,
    ...nonActivatedAttachments,
    ...activatedAttachments,
    ...errorsRoutes,
  ],
};
