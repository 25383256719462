import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const homeCallServicesRoutes: RouteRecordRaw = {
  path: 'home-call-services',
  name: ROUTES.expertHomeCallServices.name,
  redirect: { name: ROUTES.expertHomeCallConstructor.name },
  meta: {
    title: ROUTES.expertHomeCallServices.title,
    role: [],
  },
  component: () => import('./List.vue'),
  children: [
    {
      path: 'constructor',
      name: ROUTES.expertHomeCallConstructor.name,
      component: () => import('./Constructor.vue'),
      meta: {
        title: ROUTES.expertHomeCallConstructor.title,
        role: [],
      },
    },
    {
      path: 'constructor-create',
      name: ROUTES.expertHomeCallConstructorCreate.name,
      component: () => import('./ConstructorCreate.vue'),
      meta: {
        title: ROUTES.expertHomeCallConstructorCreate.title,
        role: [],
      },
      props: (route) => ({
        id: route.query.id,
        code: route.query.code,
      }),
    },
    {
      path: 'labeled',
      name: ROUTES.expertHomeCallLabeled.name,
      component: () => import('./Labeled.vue'),
      meta: {
        title: ROUTES.expertHomeCallLabeled.title,
        role: [],
      },
    },
    {
      path: 'labeled-edit',
      name: ROUTES.expertHomeCallLabeledEdit.name,
      component: () => import('./LabeledEdit.vue'),
      meta: {
        title: ROUTES.expertHomeCallLabeledEdit.title,
        role: [],
      },
      props: (route) => ({
        id: route.query.id,
        code: route.query.code,
      }),
    },
  ],
};
