import { LayoutDefault } from '@/libs/layouts';
import { ROUTES } from '@/router/routes';

export const settingsRoutes = {
  path: '/settings',
  name: ROUTES.settings.name,
  redirect: { name: 'settingsRoot' },
  meta: {
    layout: LayoutDefault,
  },
  component: () => import('./Root.vue'),
  children: [
    {
      path: 'roles',
      name: ROUTES.settingsRoles.name,
      component: () => import('./views/Roles.vue'),
    },
    {
      path: '',
      name: 'settingsRoot',
      component: () => import('./views/Settings.vue'),
    },
  ],
};
