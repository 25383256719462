import { RouteRecordRaw } from 'vue-router';

import { attributesRoutes } from '@/application/Lab/views/AdditionalClinicsAttributes/routes';
import { groupsRoutes } from '@/application/Lab/views/Groups/routes';
import { LayoutDefault } from '@/libs/layouts';
import { smartRedirect } from '@/libs/user/role/smartRedirect';

import { LabRouteNames } from './routeNames';
import { invoiceToggleRoutes } from './views/InvoiceToggle/routes';
import { laboratoriesServicesRoutes } from './views/LaboratoriesServices/routes';
import { widgetsRoutes } from './views/Widgets/routes';

export const labRoutes: RouteRecordRaw = {
  path: '/lab',
  name: LabRouteNames.lab.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: LabRouteNames.lab.title,
    role: [],
  },
  children: [
    laboratoriesServicesRoutes,
    invoiceToggleRoutes,
    groupsRoutes,
    widgetsRoutes,
    attributesRoutes,
  ],
};
