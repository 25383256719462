type SplitRole<T extends string> = [
  `${T}-view`,
  `${T}-add`,
  `${T}-edit`,
  `${T}-delete`
];

type CamelCase<S extends string> =
  S extends `${infer P1}-${infer P2}${infer P3}`
    ? `${P1}${Uppercase<P2>}${CamelCase<P3>}`
    : S;

function firstLetterToUpperCase<T extends string>(str: T): Capitalize<T> {
  return (str.charAt(0).toUpperCase() + str.slice(1)) as Capitalize<T>;
}

function capitalize<T extends string>(text: T): Capitalize<Lowercase<T>> {
  return firstLetterToUpperCase(text.toLowerCase()) as Capitalize<Lowercase<T>>;
}

function toCamelCase<T extends string = string>(str: T): CamelCase<T> {
  if (!str.includes('-')) {
    return firstLetterToUpperCase(str) as CamelCase<T>;
  }

  const result = str
    .split(/-/g)
    .map((m, index) => (index === 0 ? m.toLowerCase() : capitalize(m)))
    .join('');

  return firstLetterToUpperCase(result) as CamelCase<T>;
}

const getSplitedRoles = <T extends string>(name: T): SplitRole<T> => {
  return [`${name}-view`, `${name}-add`, `${name}-edit`, `${name}-delete`];
};

type Include<T, P> = T extends []
  ? false
  : T extends [infer Head, ...infer Tail]
  ? Head extends P
    ? true
    : P extends Head
    ? true
    : Include<Tail, P>
  : false;

export class RolesBuilder<Acc extends string[] = []> {
  private readonly adjacency = new Map<Acc[number], Acc[number][]>();

  addRole<Name extends Include<Acc, Name> extends true ? never : string>(
    role: Name
  ): RolesBuilder<[...Acc, Name]> {
    if (!this.adjacency.get(role)) {
      this.adjacency.set(role, []);
    }

    return this as any;
  }

  addConnection(role: Acc[number], childRole: Acc[number]) {
    this.adjacency.get(role)?.push(childRole);

    return this;
  }

  splitToRoleActions<Name extends Acc[number]>(
    source: Name
  ): RolesBuilder<[...Acc, ...SplitRole<Name>]> {
    const separatedRoles = getSplitedRoles(source);

    this.adjacency.get(source)?.push(...separatedRoles);

    separatedRoles.forEach((role) => this.addRole(role as any));

    return this as any;
  }

  getRoles(): { [key in Acc[number] as Capitalize<CamelCase<key>>]: key } {
    const keys = Array.from(this.adjacency.keys());

    return keys.reduce((result, key) => {
      const camelCaseKey = toCamelCase(key) as any;

      result[camelCaseKey] = key as any as Acc[number];

      return result;
    }, {} as Record<string, string>) as any;
  }

  getGraph() {
    return this.adjacency;
  }
}
