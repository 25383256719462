import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const featureToggleRoutes: RouteRecordRaw = {
  path: 'feature-toggle',
  name: ROUTES.adminFeatureToggle.name,
  redirect: { name: 'featureToggleList' },
  meta: {
    title: ROUTES.adminFeatureToggle.title,
    role: [roles.FeatureAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'featureToggleList',
      component: () => import('./List.vue'),
      meta: {
        role: [],
      },
    },
    {
      path: 'add',
      name: ROUTES.adminAddFeatureToggle.name,
      component: () => import('./Add.vue'),
      meta: {
        title: ROUTES.adminAddFeatureToggle.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.adminEditFeatureToggle.name,
      component: () => import('./Edit.vue'),
      meta: {
        title: ROUTES.adminEditFeatureToggle.title,
        role: [],
      },
      props: (route) => ({ id: route.params.id }),
    },
  ],
};
