import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const usersRoutes: RouteRecordRaw = {
  path: 'users',
  name: ROUTES.salesToolsUsers.name,
  redirect: { name: 'salesToolsUsersList' },
  meta: {
    title: ROUTES.salesToolsUsers.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'salesToolsUsersList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
