import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { ROUTES } from '@/router/routes';

import { doctorsRoutes } from './views/Doctors/routes';
import { patientsRoutes } from './views/Patients/routes';
import { quizRoutes } from './views/Quiz/routes';
import { chroniclesRemindersRoutes } from './views/Reminders/routes';

export const chroniclesRoutes: RouteRecordRaw = {
  path: '/chronicles',
  name: ROUTES.chronicles.name,
  redirect: { name: ROUTES.chroniclesQuiz.name },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    layout: LayoutDefault,
    title: ROUTES.chronicles.title,
    role: [],
  },
  children: [
    quizRoutes,
    doctorsRoutes,
    patientsRoutes,
    chroniclesRemindersRoutes,
  ],
};
