import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const medicalForm: RouteRecordRaw = {
  path: 'medical-form',
  name: ROUTES.pharmacyMedicalForm.name,
  redirect: { name: 'medicalFormList' },
  meta: {
    title: ROUTES.pharmacyMedicalForm.title,
    role: [roles.PharmacyAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: 'create',
      name: ROUTES.pharmacyMedicalFormCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.pharmacyMedicalFormCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.pharmacyMedicalFormEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.pharmacyMedicalFormEdit.title,
        role: [],
      },
    },
    {
      path: '',
      name: 'medicalFormList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
