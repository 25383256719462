import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { useAuth } from '@/libs/authorization/useAuth';
import { getCurrentRole } from '@/libs/user/role/getCurrentRole';
import { useUserPermission } from '@/libs/user/role/useUserPermission';
import { ROUTES } from '@/router/routes';

const { hasPermission, getRequiredRoles } = useUserPermission();

export function routerGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isAuth } = useAuth();

  if (to.meta.role === undefined) {
    next();
  } else if (!isAuth()) {
    next({
      name: ROUTES.signIn.name,
      query: {
        nextUrl: to.fullPath,
      },
      replace: true,
    });
  } else if (!hasPermission(to, getCurrentRole())) {
    const requiredRoles = getRequiredRoles(to);

    next({
      name: ROUTES.pageNoPermission.name,
      params: {
        requiredRoles: requiredRoles,
        routeName: (to.meta?.title as string) || '',
      },
    });
  } else {
    next();
  }
}
