import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const advertisementsRoutes: RouteRecordRaw = {
  path: 'advertisements',
  name: ROUTES.salesToolsAdvertisements.name,
  redirect: { name: 'salesToolsAdvertisementsList' },
  meta: {
    title: ROUTES.salesToolsAdvertisements.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'salesToolsAdvertisementsList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.salesToolsAdvertisementsCreate.name,
      component: () => import('./Create.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsAdvertisementsCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.salesToolsAdvertisementsEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsAdvertisementsEdit.title,
        role: [],
      },
    },
  ],
};
