import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const receptionTypes: RouteRecordRaw = {
  path: 'reception-types',
  name: ROUTES.receptionTypes.name,
  redirect: { name: 'receptionTypesList' },
  meta: {
    title: ROUTES.receptionTypes.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'receptionTypesList',
      meta: {
        role: [roles.ReceptionView],
      },
      component: () => import('./List.vue'),
    },
    {
      path: ':id',
      name: ROUTES.receptionTypesEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.receptionTypesEdit.title,
        role: [roles.ReceptionEdit],
      },
    },
  ],
};
