<template>
  <div class="smeda-container">
    <div :class="$style.container">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  padding: 0 24px;
}
</style>
