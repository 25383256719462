import { ROUTES } from '@/router/routes';

export const paymentsCompaniesRoutes = {
  path: 'companies',
  name: ROUTES.paymentsCompanies.name,
  meta: {
    title: ROUTES.paymentsCompanies.title,
    role: [],
  },
  redirect: { name: 'PaymentsCompaniesRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: 'add',
      name: ROUTES.paymentsCompaniesAdd.name,
      component: () => import('./Add.vue'),
      meta: {
        title: ROUTES.paymentsCompaniesAdd.title,
        role: [],
      },
    },
    {
      path: '',
      name: 'PaymentsCompaniesRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
