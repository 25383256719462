import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const markersRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'markers',
    name: ROUTES.markers.name,
    meta: {
      title: ROUTES.markers.title,
      role: [roles.MarketplaceAdmin],
    },
    component: () => import('./Markers.vue'),
  },
];
