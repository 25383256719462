import type { Directive, DirectiveBinding } from 'vue';

export const SMED_PIVOT = 'smed-pivot';

function mounted(el: HTMLElement, { value }: DirectiveBinding<string>) {
  el.dispatchEvent(
    new CustomEvent(SMED_PIVOT, {
      detail: {
        method: 'add',
        id: value,
      },
      bubbles: true,
    })
  );
}

function beforeUnmount(el: HTMLElement, { value }: DirectiveBinding<string>) {
  el.dispatchEvent(
    new CustomEvent(SMED_PIVOT, {
      detail: {
        method: 'remove',
        id: value,
      },
      bubbles: true,
    })
  );
}

export const PivotDirective: Directive<HTMLElement, string> = {
  mounted,
  beforeUnmount,
};
