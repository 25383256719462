import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const doctors: RouteRecordRaw = {
  path: 'doctors',
  name: ROUTES.receptionsDoctors.name,
  redirect: { name: 'receptionsDoctorsList' },
  meta: {
    title: ROUTES.receptionsDoctors.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'receptionsDoctorsList',
      meta: {
        role: [roles.ReceptionView],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'add',
      name: ROUTES.receptionsAddDoctor.name,
      meta: {
        title: ROUTES.receptionsAddDoctor.title,
        role: [roles.ReceptionAdd],
      },
      component: () => import('./Add.vue'),
    },
  ],
};
