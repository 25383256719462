import { computed, inject, provide, ref, watch } from 'vue';

const WIREFRAMES = ['BASE', 'WIDE', 'SMART_FILTERS'];
const classPrefix = 'smed-admin-wireframe-';

export const WIREFRAME_LOCALSTORAGE_KEY = '__smed-admin-wireframe-key';

const WIREFRAME_DUMB_FILTERS_TOKEN = Symbol('WIREFRAME_DUMB_FILTERS_TOKEN');

export function useWireframe() {
  const localWireframe = localStorage.getItem(WIREFRAME_LOCALSTORAGE_KEY);
  const isExist = !!localWireframe && WIREFRAMES.includes(localWireframe);

  const wireframe = ref(isExist ? localWireframe : WIREFRAMES[0]);

  watch(
    wireframe,
    (value) => {
      WIREFRAMES.forEach((key) => {
        const className = `${classPrefix}${key}`;

        document.body.classList.remove(className);
      });

      document.body.classList.add(`${classPrefix}${value}`);

      localStorage.setItem(WIREFRAME_LOCALSTORAGE_KEY, value);
    },
    { immediate: true }
  );

  const dumbFilters = () => {
    provide(WIREFRAME_DUMB_FILTERS_TOKEN, false);
  };

  const isFiltersInSidebar = computed(() => {
    const isSmart = inject(WIREFRAME_DUMB_FILTERS_TOKEN, true);

    return isSmart && wireframe.value === 'SMART_FILTERS';
  });

  return {
    wireframe,
    isFiltersInSidebar,
    dumbFilters,
  };
}
