import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const nonActivatedAttachments: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'non-activated-attachments',
    name: ROUTES.nonActivatedAttachments.name,
    meta: {
      title: ROUTES.nonActivatedAttachments.title,
      role: [roles.MarketplaceAdmin],
    },
    component: () => import('./NonActivatedAttachments.vue'),
  },
];
