import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const patientsRoutes: RouteRecordRaw = {
  path: 'patients',
  name: ROUTES.chroniclesPatients.name,
  meta: {
    title: ROUTES.chroniclesPatients.title,
    role: [roles.Chronic_nurse],
  },
  redirect: { name: ROUTES.chroniclesPatientsList.name },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: ROUTES.chroniclesPatientsList.name,
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: ':id',
      name: ROUTES.chroniclesPatientsPreview.name,
      meta: {
        title: ROUTES.chroniclesPatientsPreview.title,
        role: [],
      },
      props: (route) => ({ id: route.params.id }),
      component: () => import('./Preview.vue'),
      redirect: (to) => {
        return {
          path: `/chronicles/patients/${to.params.id}/info`,
          props: { id: to.params.id },
        };
      },
      children: [
        {
          path: 'info',
          name: ROUTES.chroniclesPatientsPreviewInfo.name,
          props: (route) => ({ id: route.params.id }),
          component: () => import('./components/PatientInfo.vue'),
        },
        {
          path: 'quizzes',
          name: ROUTES.chroniclesPatientsPreviewQuizzes.name,
          props: (route) => ({ id: route.params.id }),
          component: () => import('./components/PatientQuizzes.vue'),
        },
        {
          path: 'pills',
          name: ROUTES.chroniclesPatientsPreviewPills.name,
          props: (route) => ({ id: route.params.id }),
          component: () => import('./components/PatientPills.vue'),
        },
        {
          path: 'referrals',
          name: ROUTES.chroniclesPatientsPreviewReferrals.name,
          props: (route) => ({ id: route.params.id }),
          component: () => import('./components/PatientReferrals.vue'),
        },
      ],
    },
  ],
};
