import { RolesBuilder } from './rolesBuilder';

const builder = new RolesBuilder()
  .addRole('Product Owner')
  // marketplace
  .addRole('users-admin')
  .addRole('marketplace-admin')
  .addRole('marketplace-principal')
  .addConnection('marketplace-admin', 'marketplace-principal')
  // end marketplace
  .addRole('connector-admin')
  // pharmacy
  .addRole('pharmacy-admin')
  .addRole('pharmacy-operator')
  .addConnection('pharmacy-admin', 'pharmacy-operator')
  // end pharmacy
  .addRole('account-admin')
  .addRole('feature-admin')
  .addRole('clinics-admin')
  .addRole('documents-admin')
  // reception
  .addRole('reception-admin')
  .addRole('reception-support')
  .addConnection('reception-admin', 'reception-support')
  .addRole('reception')
  .splitToRoleActions('reception')
  .addConnection('reception-admin', 'reception')
  .addConnection('reception-support', 'reception-view')
  // end reception
  .addRole('st-admin')
  .addRole('paymentprovider-admin')
  .addRole('paymentprovider-support')
  .addConnection('paymentprovider-admin', 'paymentprovider-support')
  // referrals
  .addRole('referral-admin')
  .addRole('referral-support')
  .addConnection('referral-admin', 'referral-support')
  .addRole('referral')
  .splitToRoleActions('referral')
  .addConnection('referral-admin', 'referral')
  .addConnection('referral-support', 'referral-view')
  // end referrals
  .addRole('analyses-dynamic-admin')
  .addRole('config-admin')
  // chronics
  .addRole('chronic_nurse')
  .addRole('chronic_admin')
  .addConnection('chronic_admin', 'chronic_nurse')
  // Product Owner connection
  .addConnection('Product Owner', 'marketplace-admin')
  .addConnection('Product Owner', 'connector-admin')
  .addConnection('Product Owner', 'pharmacy-admin')
  .addConnection('Product Owner', 'account-admin')
  .addConnection('Product Owner', 'feature-admin')
  .addConnection('Product Owner', 'clinics-admin')
  .addConnection('Product Owner', 'documents-admin')
  .addConnection('Product Owner', 'reception-admin')
  .addConnection('Product Owner', 'st-admin')
  .addConnection('Product Owner', 'paymentprovider-admin')
  .addConnection('Product Owner', 'referral-admin')
  .addConnection('Product Owner', 'analyses-dynamic-admin')
  .addConnection('Product Owner', 'config-admin')
  .addConnection('Product Owner', 'users-admin');

export const roles = builder.getRoles();
export const rolesGraph = builder.getGraph();

export type Role = typeof builder extends RolesBuilder<infer U>
  ? U[number]
  : never;
