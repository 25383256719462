import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';

export const referralsRouteName = {
  referrals: {
    title: 'Направления',
    name: 'Referrals',
  },
  preview: {
    title: 'Просмотр направления',
    name: 'RefferalsPreview',
  },
};

export const referralsRoutes: RouteRecordRaw = {
  path: 'referrals',
  name: referralsRouteName.referrals.name,
  redirect: { name: 'referralsListRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    layout: LayoutDefault,
    title: referralsRouteName.referrals.title,
    role: [roles.ReferralSupport],
  },
  children: [
    {
      path: '',
      name: 'referralsListRoot',
      component: () => import('./views/List.vue'),
      meta: {
        role: [],
      },
    },
    {
      path: ':id',
      name: referralsRouteName.preview.name,
      component: () => import('./views/Preview.vue'),
      meta: {
        title: referralsRouteName.preview.title,
        role: [],
      },
      props: (route) => ({ id: route.params.id }),
    },
  ],
};
