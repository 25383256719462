import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const productsRoutes: RouteRecordRaw = {
  path: 'products',
  name: ROUTES.products.name,
  meta: {
    title: ROUTES.products.title,
    role: [roles.MarketplaceAdmin],
  },
  redirect: { name: 'ProgramsProductsRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'ProgramsProductsRoot',
      meta: {
        role: [],
      },
      component: () => import('./Products.vue'),
    },
    {
      path: ':id',
      name: ROUTES.editProduct.name,
      meta: {
        title: ROUTES.editProduct.title,
        role: [],
      },
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
    },

    {
      path: 'add',
      name: ROUTES.addProduct.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.addProduct.title,
        role: [],
      },
    },
  ],
};
