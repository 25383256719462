import { RouteRecordRaw } from 'vue-router';

import { payOrders } from '@/application/Pharmacy/views/PayOrders/routes';
import { LayoutDefault } from '@/libs/layouts';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

import { banners } from './views/Banners/routes';
import { bannerSlotsRoute } from './views/BannerSlots/routes';
import { clinics } from './views/Clinics/routes';
import { comprasionOfGoods } from './views/ComprasionOfGoods/routes';
import { inn } from './views/Inn/routes';
import { medicalForm } from './views/MedicalForm/routes';
import { orders } from './views/Orders/routes';
import { partnerProducts } from './views/PartnerProducts/routes';
import { partnerRegions } from './views/PartnerRegions/routes';
import { partners } from './views/Partners/routes';
import { pickupPoints } from './views/PickupPoints/routes';
import { producers } from './views/Producers/routes';
import { products } from './views/Products/routes';
import { promotions } from './views/Promotions/routes';
import { regions } from './views/Regions/routes';
import { reviews } from './views/Reviews/routes';
import { tags } from './views/Tags/routes';

export const pharmacyRoutes: RouteRecordRaw = {
  path: '/pharmacy',
  name: ROUTES.pharmacy.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.pharmacy.title,
    role: [],
  },
  children: [
    clinics,
    partners,
    partnerProducts,
    pickupPoints,
    producers,
    inn,
    medicalForm,
    products,
    comprasionOfGoods,
    regions,
    partnerRegions,
    tags,
    orders,
    payOrders,
    reviews,
    banners,
    bannerSlotsRoute,
    promotions,
  ],
};
