import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const historyRoutes: RouteRecordRaw = {
  path: 'history',
  name: ROUTES.salesToolsHistory.name,
  redirect: { name: ROUTES.salesToolsHistoryList.name },
  meta: {
    title: ROUTES.salesToolsHistory.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: ROUTES.salesToolsHistoryList.name,
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
