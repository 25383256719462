import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';

export const contentManagerRoute: RouteRecordRaw = {
  path: '/content-manager',
  name: 'ContentManager',
  redirect: '/content-manager/content',
  meta: {
    layout: LayoutDefault,
    title: 'Управление контентом',
    role: [roles.MarketplaceAdmin],
  },
  children: [
    {
      path: 'content',
      name: 'ContentManagerContent',
      component: () => import('./Content/List.vue'),
      meta: {
        title: 'Контент',
        role: [],
      },
    },
    {
      path: 'campaigns',
      name: 'ContentManagerCampaignsRoot',
      redirect: { name: 'ContentManagerCampaigns' },
      meta: {
        title: 'Кампании',
        role: [],
      },
      children: [
        {
          path: '',
          name: 'ContentManagerCampaigns',
          component: () => import('./Campaigns/List.vue'),
          meta: {
            title: '',
            role: [],
          },
        },
        {
          path: ':id',
          name: 'ContentManagerCampaignEdit',
          component: () => import('./Campaigns/Edit.vue'),
          meta: {
            title: 'Редактирование кампании',
            role: [],
          },
          props: (route) => ({ id: Number(route.params.id) }),
        },
        {
          path: 'new',
          name: 'ContentManagerCampaignNew',
          component: () => import('./Campaigns/New.vue'),
          meta: {
            title: 'Новая кампания',
            role: [],
          },
        },
      ],
    },
    {
      path: 'user-groups',
      name: 'ContentManagerUserGroupsRoot',
      redirect: { name: 'ContentManagerUserGroups' },
      meta: {
        title: 'Пользовательские группы',
        role: [],
      },
      children: [
        {
          path: '',
          name: 'ContentManagerUserGroups',
          component: () => import('./UserGroups/List.vue'),
          meta: {
            title: '',
            role: [],
          },
        },
        {
          path: 'new',
          name: 'ContentManagerUserGroupsNew',
          component: () => import('./UserGroups/New.vue'),
          meta: {
            title: 'Новая группа',
            role: [],
          },
        },
        {
          path: 'edit/:id',
          name: 'ContentManagerUserGroupsEdit',
          component: () => import('./UserGroups/Edit.vue'),
          meta: {
            title: 'Редактировать группу',
            role: [],
          },
          props: (route) => ({ id: route.params.id }),
        },
      ],
    },
    {
      path: 'slots',
      name: 'ContentMangerSlotsRoot',
      redirect: { name: 'ContentManagerSlots' },
      meta: {
        title: 'Слоты',
        role: [],
      },
      children: [
        {
          path: '',
          name: 'ContentManagerSlots',
          component: () => import('./Slots/List.vue'),
          meta: {
            title: '',
            role: [],
          },
        },
        {
          path: ':id',
          name: 'ContentManagerSlotEdit',
          component: () => import('./Slots/Edit.vue'),
          meta: {
            title: 'Редактирование слота',
            role: [],
          },
          props: (route) => ({
            id: Number(route.params.id),
            page: Number(route.query.page || 1),
            size: Number(route.query.size || 100),
          }),
        },
        {
          path: 'new',
          name: 'ContentManagerSlotNew',
          component: () => import('./Slots/New.vue'),
          meta: {
            title: 'Добавление слота',
            role: [],
          },
        },
      ],
    },
  ],
};
