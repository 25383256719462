import { RouteRecordRaw } from 'vue-router';

import { distributionRoutes } from '@/application/SalesTools/views/Distribution/routes';
import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

import { advertisementsRoutes } from './views/Advertisements/routes';
import { campaignsRoutes } from './views/Campaigns/routes';
import { historyRoutes } from './views/History/routes';
import { slotsRoutes } from './views/Slots/routes';
import { usersRoutes } from './views/Users/routes';

export const salesTools: RouteRecordRaw = {
  path: '/sales-tools',
  name: ROUTES.salesTools.name,
  redirect: { name: campaignsRoutes.name },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    layout: LayoutDefault,
    title: ROUTES.salesTools.title,
    role: [roles.StAdmin],
  },
  children: [
    campaignsRoutes,
    advertisementsRoutes,
    distributionRoutes,
    slotsRoutes,
    usersRoutes,
    historyRoutes,
  ],
};
