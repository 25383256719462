import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const partnerRegions: RouteRecordRaw = {
  path: 'partner-regions',
  name: ROUTES.pharmacyPartnerRegions.name,
  redirect: { name: 'pharmacyPartnerRegionsList' },
  meta: {
    title: ROUTES.pharmacyPartnerRegions.title,
    role: [roles.PharmacyAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'pharmacyPartnerRegionsList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.pharmacyPartnerRegionsCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.pharmacyPartnerRegionsCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.pharmacyPartnerRegionsEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.pharmacyPartnerRegionsEdit.title,
        role: [],
      },
    },
  ],
};
