import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const cacheEraseRoutes: RouteRecordRaw = {
  path: 'cache-erase',
  name: ROUTES.cacheErase.name,
  redirect: { name: 'cacheEraseRoot' },
  meta: {
    title: ROUTES.cacheErase.title,
    role: [roles.ReceptionView],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'cacheEraseRoot',
      meta: {
        role: [],
      },
      component: () => import('./Erase.vue'),
    },
  ],
};
