import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const activatedAttachments: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'activated-attachments',
    name: ROUTES.activatedAttachments.name,
    meta: {
      title: ROUTES.activatedAttachments.title,
      role: [roles.MarketplaceAdmin],
    },
    component: () => import('./ActivatedAttachments.vue'),
  },
];
