import { ref } from 'vue';

import { defineStore } from 'pinia';

type StateRoute = { to: string; name: string };

const safeGetRoutesFromLocalStorage = (
  localStorageKey: string,
  defaultValue: StateRoute[]
): StateRoute[] => {
  const value = localStorage.getItem(localStorageKey) || '[]';

  try {
    const items = JSON.parse(value) as unknown;

    return (Array.isArray(items) && items.filter(isStateRoute)) || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

const isSameRoute = (first: StateRoute, second: StateRoute): boolean => {
  return first.to === second.to && first.name === second.name;
};

const isStateRoute = (data: unknown): data is StateRoute => {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  const value = data as Partial<StateRoute>;

  return 'to' in value && !!value.to && 'name' in value && !!value.name;
};

export const useFavoriteRoutes = (localStorageKey: string) =>
  defineStore('favorite-routes', () => {
    const routes = ref<ReadonlyArray<StateRoute>>(
      safeGetRoutesFromLocalStorage(localStorageKey, [])
    );

    const toggleRoute = (value: StateRoute) => {
      const hasInRoutes = routes.value.find((item) => {
        return isSameRoute(item, value);
      });

      const newRoutes = hasInRoutes
        ? routes.value.filter((item) => !isSameRoute(item, value))
        : [...routes.value, value];

      routes.value = newRoutes;
      localStorage.setItem(localStorageKey, JSON.stringify(newRoutes));
    };

    const isFavorite = (route: StateRoute) => {
      return (
        routes.value.length > 0 &&
        !!routes.value.find((item) => isSameRoute(item, route))
      );
    };

    return {
      routes,
      toggleRoute,
      isFavorite,
    };
  })();
