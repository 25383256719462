import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const documentsRoutes: RouteRecordRaw = {
  path: 'documents',
  name: ROUTES.documents.name,
  meta: {
    title: ROUTES.documents.title,
    role: [roles.MarketplaceAdmin],
  },
  redirect: { name: 'ProgramsDocumentsRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'ProgramsDocumentsRoot',
      meta: {
        role: [],
      },
      component: () => import('./Documents.vue'),
    },
    {
      path: 'add',
      name: ROUTES.addDocument.name,
      meta: {
        title: ROUTES.addDocument.title,
        role: [],
      },
      component: () => import('./Document.vue'),
    },
    {
      path: ':id',
      name: ROUTES.editDocument.name,
      meta: {
        title: ROUTES.editDocument.title,
        role: [],
      },
      props: (route) => ({ id: route.params.id }),
      component: () => import('./Document.vue'),
    },
  ],
};
