import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

import { paymentsHistoryRoutes } from './views/PaymentsHistory/routes';
import { usersDepositsRoutes } from './views/UsersDeposits/routes';

export const depositsRoutes: RouteRecordRaw = {
  path: '/deposits',
  name: ROUTES.deposits.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: { name: paymentsHistoryRoutes.name },
  meta: {
    layout: LayoutDefault,
    title: ROUTES.deposits.title,
    role: [roles.MarketplaceAdmin],
  },
  children: [paymentsHistoryRoutes, usersDepositsRoutes],
};
