import { authorization } from '@/bootstrap';
import { jwtDecode } from '@/libs/http/jwtDecode';

export function getUserName(): string | null {
  try {
    const token = authorization.tokenStorage.get();
    const result = token ? jwtDecode(token) : null;

    return result?.preferred_username || null;
  } catch (error) {
    return null;
  }
}
