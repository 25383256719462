import { ROUTES } from '@/router/routes';

export const paymentsTransactionsRoutes = {
  path: 'transactions',
  name: ROUTES.paymentsTransactions.name,
  meta: {
    title: ROUTES.paymentsTransactions.title,
    role: [],
  },
  redirect: { name: 'PaymentsTransactionsRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'PaymentsTransactionsRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
