import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const partners: RouteRecordRaw = {
  path: 'partners',
  name: ROUTES.pharmacyPartners.name,
  redirect: { name: 'PartnersList' },
  meta: {
    title: ROUTES.pharmacyPartners.title,
    role: [roles.PharmacyAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: 'create',
      name: ROUTES.pharmacyPartnersCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.pharmacyPartnersCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.pharmacyPartnersEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.pharmacyPartnersEdit.title,
        role: [],
      },
    },
    {
      path: '',
      name: 'PartnersList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
