import { RouteMeta, RouteRecordRaw } from 'vue-router';

import { memo } from '@admin/shared';

import { hasGraphPath } from './helpers/hasGraphPath';
import { Role, rolesGraph } from './roles';

type CustomRouteRecordRaw = RouteRecordRaw & {
  meta: RouteMeta & {
    isBlocked: true;
  };
  children?: CustomRouteRecordRaw[];
};

export function resolveRouteByRoles(
  routes: ReadonlyArray<RouteRecordRaw>,
  roles: ReadonlyArray<Role | string>,
  showBlockedRoutes: boolean
): CustomRouteRecordRaw[] {
  const dfs = (
    routes: ReadonlyArray<RouteRecordRaw>,
    isBlocked = false
  ): any => {
    if (routes.length === 0) {
      return [];
    }

    const first = routes[0];
    const rest = routes.slice(1);

    const hasRoleDescription =
      !!first.meta && !!first.meta.role && Array.isArray(first.meta.role);

    if (!hasRoleDescription) {
      return dfs(rest, isBlocked);
    }

    const routeRoles = first.meta!.role! as ReadonlyArray<string>;

    const hasCurrentRole =
      routeRoles.length === 0 ||
      routeRoles.every((role) => hasRoleAccess(role as Role, roles as Role[]));

    if (!hasCurrentRole) {
      if (!showBlockedRoutes) {
        return dfs(rest, isBlocked);
      }

      const children = dfs(first.children || [], true);

      return [
        { ...first, meta: { ...first.meta, isBlocked: true }, children },
      ].concat(dfs(rest, isBlocked));
    }

    const compChilds = first.children || [];
    const isEmpty = compChilds.length === 0;

    if (isEmpty) {
      return [
        {
          ...first,
          meta: { ...first.meta, isBlocked: isBlocked },
          children: [],
        },
      ].concat(dfs(rest, isBlocked));
    }

    const childs = dfs(compChilds, isBlocked);

    if (childs.length === 0) {
      return dfs(rest, isBlocked);
    }

    const everyChildBlocked = childs.every(
      (m: { meta: { isBlocked: boolean } }) => m.meta.isBlocked
    );

    return [
      {
        ...first,
        meta: { ...first.meta, isBlocked: everyChildBlocked },
        children: childs,
      },
    ].concat(dfs(rest, isBlocked));
  };

  return dfs(routes);
}

function _hasRoleAccess(requiredRole: Role, roles: ReadonlyArray<Role>) {
  return roles.some((role) => hasGraphPath(role, requiredRole, rolesGraph));
}

export const hasRoleAccess = memo(_hasRoleAccess);
