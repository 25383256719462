import { RouteLocation, RouteLocationRaw } from 'vue-router';

import { getCurrentRole } from './getCurrentRole';
import { useUserPermission } from './useUserPermission';

const { hasPermissionByRouteName } = useUserPermission();

/**
 *
 * smartRedirect необходим, когда у родителя нет роли, а детей закрыты под роль
 * @param to
 * @param defaultRoute
 */
export function smartRedirect(
  to: RouteLocation,
  defaultRoute: string | null = null
): RouteLocationRaw {
  const rootRoute = to.matched[0];
  const children = rootRoute.children;
  const roles = getCurrentRole();

  if (defaultRoute) {
    const hasPermission = hasPermissionByRouteName(defaultRoute, roles);

    if (hasPermission) {
      return {
        name: defaultRoute,
      };
    }
  }

  const firstWithRole = children.find((route) => {
    if (!route.name) {
      console.error(`Укажи название для ${to.fullPath} ${route.path}`);

      return false;
    }

    return hasPermissionByRouteName(route.name as string, roles);
  });

  if (!firstWithRole) {
    return {
      path: '/',
    };
  }

  return {
    name: firstWithRole.name,
  };
}
