import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const promocodesRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'promocodes',
    name: ROUTES.promocodes.name,
    meta: {
      title: ROUTES.promocodes.title,
      role: [roles.MarketplaceAdmin],
    },
    component: () => import('./Promocodes.vue'),
  },
];
