import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { errorMessagesByCodes } from '@/libs/http/errorMessages';

import { Connection } from './Connection.interface';
import { Request } from './Request.type';
import { Response } from './Response.type';

export class Axios implements Connection {
  private readonly instance: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.instance = axios.create(config);
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  async request({ url, method, data, config }: Request): Promise<Response> {
    const payload: any = {};

    try {
      const response = await this.instance.request({
        url,
        method,
        data,
        ...config,
      });

      payload.data = response.data;

      return {
        status: response.status,
        success: true,
        payload,
      };
    } catch (error: any) {
      const code = error.response?.status || error;
      const payloadMessage =
        errorMessagesByCodes[code] ||
        (typeof error.response?.data === 'string' ? error.response?.data : '');

      if (payloadMessage) {
        payload.message = payloadMessage;
      }

      if (typeof error.response?.data === 'object') {
        payload.messages = error.response?.data;
      }

      return {
        status: error.response?.status,
        success: false,
        payload,
      };
    }
  }
}

export class SimplifyAxios implements Connection {
  private readonly instance: AxiosInstance;

  constructor(config?: AxiosRequestConfig) {
    this.instance = axios.create(config);
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }

  request<T, K>(config: AxiosRequestConfig<K>): Promise<T> {
    return this.instance.request<T>(config).then((response) => response.data);
  }
}
