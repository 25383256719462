import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const specialtiesInfoclinic: RouteRecordRaw = {
  path: 'specializations',
  name: ROUTES.specializationsInfoclinic.name,
  redirect: { name: 'specializationsInfoclinic' },
  meta: {
    title: ROUTES.specializationsInfoclinic.title,
    role: [roles.ReceptionView],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'specializationsInfoclinic',
      meta: {
        role: [roles.ReceptionView],
      },
      component: () => import('./List.vue'),
    },
  ],
};
