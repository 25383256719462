import { ROUTES } from '@/router/routes';

export const paymentsFiscalEndpointsRoutes = {
  path: 'fiscal-endpoints',
  name: ROUTES.paymentsFiscalEndpoints.name,
  meta: {
    title: ROUTES.paymentsFiscalEndpoints.title,
    role: [],
  },
  redirect: { name: 'PaymentsFiscalEndpointsRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: 'add',
      name: ROUTES.paymentsFiscalEndpointsAdd.name,
      component: () => import('./Add.vue'),
      meta: {
        title: ROUTES.paymentsFiscalEndpointsAdd.title,
        role: [],
      },
    },
    {
      path: '',
      name: 'PaymentsFiscalEndpointsRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
