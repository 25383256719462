export function hasGraphPath<T>(node: T, distinction: T, graph: Map<T, T[]>) {
  const visited = new Set<T>();
  const queue = [node];

  while (queue.length > 0) {
    const current = queue.shift() as T;

    if (current === distinction) {
      return true;
    }

    if (visited.has(current)) {
      continue;
    }

    visited.add(current);

    const children = graph.get(current) || [];

    children.forEach((child) => queue.push(child));
  }

  return false;
}
