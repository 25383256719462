import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const LSPaymentsRoutes = {
  path: 'ls-payments',
  name: ROUTES.patientLaboratorySupermarketPayments.name,
  meta: {
    title: ROUTES.patientLaboratorySupermarketPayments.title,
    role: [roles.PaymentproviderAdmin],
  },
  component: () => import('@/application/Payments/views/LSPayments/List.vue'),
};
