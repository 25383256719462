import decode from 'jwt-decode';

import { Role } from '../user/role/roles';

type DecodedToken = {
  preferred_username?: string;
  realm_access?: {
    roles: ReadonlyArray<Role>;
  };
};

export function jwtDecode(token: string) {
  return decode<DecodedToken>(token);
}
