import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const citiesRoutes: RouteRecordRaw = {
  path: 'cities',
  name: ROUTES.adminCities.name,
  redirect: { name: 'adminCitiesList' },
  meta: {
    title: ROUTES.adminCities.title,
    role: [roles.MarketplaceAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'adminCitiesList',
      meta: {
        role: [roles.ClinicsAdmin],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.adminCitiesCreate.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ isCreate: true }),
      meta: {
        title: ROUTES.adminCitiesCreate.title,
        role: [roles.ClinicsAdmin],
      },
    },
    {
      path: 'edit/:id',
      name: ROUTES.adminCitiesEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.adminCitiesEdit.title,
        role: [roles.ClinicsAdmin],
      },
    },
  ],
};
