import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

const PharmacyComponent = () =>
  import(/* webpackChunkName pharmacy */ '@/libs/layouts/Simple.vue');
const PayOrdersListComponent = () =>
  import(/* webpackChunkName pay-orders-list */ './List.vue');
const PayOrdersItemEditComponent = () =>
  import(/* webpackChunkName pay-orders-item-edit */ './Edit.vue');

export const payOrders: RouteRecordRaw = {
  path: 'pay-orders',
  name: ROUTES.pharmacyPayOrders.name,
  redirect: { name: 'pharmacyPayOrdersRoot' },
  meta: {
    title: ROUTES.pharmacyPayOrders.title,
    role: [roles.PharmacyOperator],
  },
  component: PharmacyComponent,

  children: [
    {
      path: '',
      name: 'pharmacyPayOrdersRoot',
      meta: {
        role: [],
      },
      component: PayOrdersListComponent,
    },
    {
      path: ':id',
      name: ROUTES.pharmacyPayOrdersItemEdit.name,
      component: PayOrdersItemEditComponent,
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.pharmacyPayOrdersItemEdit.title,
        role: [],
      },
    },
  ],
};
