const UUID_MASK = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
const UUID_MASK_CHAR_REGEX = /[xy]/g;

export function generateUUID(): string {
  let d = new Date().getTime();

  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now(); // use high-precision timer if available
  }

  return UUID_MASK.replace(UUID_MASK_CHAR_REGEX, (char) => {
    const rand = (d + Math.random() * 16) % 16 | 0;

    d = Math.floor(d / 16);

    return (char === 'x' ? rand : (rand & 0x3) | 0x8).toString(16);
  });
}
