import { ROUTES } from '@/router/routes';

export const paymentsSourceRoutes = {
  path: 'sources',
  name: ROUTES.paymentsSources.name,
  meta: {
    title: ROUTES.paymentsSources.title,
    role: [],
  },
  redirect: { name: 'PaymentsSourceRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: 'add',
      name: ROUTES.paymentsSourcesAdd.name,
      component: () => import('./Add.vue'),
      meta: {
        title: ROUTES.paymentsSourcesAdd.title,
        role: [],
      },
    },
    {
      path: '',
      name: 'PaymentsSourceRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
