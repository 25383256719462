import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const polygonsRoutes: RouteRecordRaw = {
  path: 'polygons',
  name: ROUTES.expertPolygons.name,
  redirect: { name: 'polygonsRoot' },
  meta: {
    title: ROUTES.expertPolygons.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'polygonsRoot',
      component: () => import('./List.vue'),
      meta: {
        role: [],
      },
    },
    {
      path: ':city',
      name: ROUTES.expertPolygonsEdit.name,
      component: () => import('./Edit.vue'),
      meta: {
        title: ROUTES.expertPolygonsEdit.title,
        role: [],
      },
      props: (route) => ({ city: route.params.city }),
    },
  ],
};
