import { computed } from 'vue';
import { RouteRecordRaw, useRouter } from 'vue-router';

import { getCurrentRole } from '@/libs/user/role/getCurrentRole';

import { Role } from '../user/role/roles';
import { useUserPermission } from '../user/role/useUserPermission';

type Section = {
  title: string;
  disabled: boolean;
} & (
  | {
      to: {
        name: string;
      };
    }
  | {
      children: Section[];
    }
);

export function useNavigationSections() {
  const allRoutes = useRouter().options.routes;
  const { hasPermissionByRouteName, showBlockedRoutes } = useUserPermission();
  const currentRoles = getCurrentRole();

  const getIsDisabled = (route: RouteRecordRaw) => {
    const roles = route.meta!.role as Role[];

    if (!roles) {
      return true;
    }

    if (roles.length === 0) {
      return false;
    }

    return !hasPermissionByRouteName(route.name! as string, currentRoles);
  };

  const mapToSection = (
    route: RouteRecordRaw,
    nested = 0,
    parentDisabled = false
  ): Section => {
    const hasChildren = route.children && route.children.length > 0;

    if (!hasChildren || nested > 2) {
      const disabled =
        parentDisabled ||
        getIsDisabled(route) ||
        (!!route.children &&
          route.children.length > 0 &&
          route.children.every((child) => getIsDisabled(child)));

      return {
        title: route.meta!.title as string,
        disabled,
        to: {
          name: route.name as string,
        },
      };
    }

    const currentDisabled = parentDisabled || getIsDisabled(route);
    const children = getSections(route.children!, nested + 1, currentDisabled);
    const disabled =
      currentDisabled ||
      (children.length > 0 && children.every(({ disabled }) => disabled));

    return {
      title: route.meta!.title as string,
      disabled: disabled,
      children: children,
    };
  };

  const getSections = (
    routes: ReadonlyArray<RouteRecordRaw>,
    nested = 0,
    parentDisabled = false
  ): Section[] => {
    const result: Section[] = [];

    routes.forEach((route) => {
      if (
        route.meta &&
        route.meta.title &&
        !route.path.includes(':') &&
        route.name
      ) {
        result.push(mapToSection(route, nested + 1, parentDisabled));
      }
    });

    return result;
  };

  const sections = getSections(allRoutes);

  const filterDisabled = (item: Section): Section => {
    const hasChildren = 'children' in item && item.children.length > 0;

    if (hasChildren) {
      return {
        ...item,
        children: item.children
          .filter(({ disabled }) => !disabled)
          .map(filterDisabled),
      };
    }

    return item;
  };

  const withOrWithoutDisabled = computed(() => {
    const showBlocked = showBlockedRoutes.value;

    if (showBlocked) {
      return sections;
    }

    const result: Section[] = [];

    sections.forEach((item) => {
      if (!item.disabled) {
        result.push(filterDisabled(item));
      }
    });

    return result;
  });

  return withOrWithoutDisabled;
}
