export const telemedRouteNames = {
  root: {
    name: 'TelemedRoot',
    title: 'Телемедицина',
  },
  consultations: {
    name: 'TelemedConsultations',
    title: 'Список консультаций',
  },
  consultationItem: {
    name: 'TelemedConsultationItem',
    title: 'Консультация',
  },
  activity: {
    name: 'TelemedActivity',
    title: 'Активность врачей',
  },
  report: {
    name: 'TelemedReport',
    title: 'Обращение врачей',
  },
  schedule: {
    name: 'TelemedSchedule',
    title: 'Расписание врачей',
  },
} as const;
