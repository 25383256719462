import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { CommonItem } from '@admin/shared';
import { getObjectWithoutEmptyValues } from '@admin/shared';

const pageIsLoading = ref<boolean>(false);

export function useCommon() {
  const router = useRouter();
  const route = useRoute();

  const toggleSpinner = (isLoading: boolean) =>
    (pageIsLoading.value = isLoading);

  const changePage = async (page: number) => {
    router.push({
      query: {
        ...route.query,
        page,
      },
    });
  };

  const filters = ref({});

  const setFilters = (newFilters: CommonItem) => {
    const withoutEmpty = getObjectWithoutEmptyValues({
      ...filters.value,
      ...newFilters,
    });

    filters.value = withoutEmpty;
  };

  const filtersWasChanged = (filters: CommonItem) => {
    setFilters({ ...filters, page: 1 });
  };

  return {
    pageIsLoading,
    toggleSpinner,
    changePage,
    filtersWasChanged,
    setFilters,
    filters,
  };
}
