import { createApp } from 'vue';

import '@/styles/common.scss';

import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import {
  defaultSmedInstallOptions,
  SmedInstallPlugin,
} from '@smartmed/ui/plugins';
import { createPinia } from 'pinia';

import App from './App.vue';
import { ThemePlugin } from './plugins/theme/plugin';
import router from './router';

const app = createApp(App);
const store = createPinia();

if (
  GLOBAL_CONFIG.ENVIRONMENT === 'production' ||
  GLOBAL_CONFIG.ENVIRONMENT === 'staging'
) {
  Sentry.init({
    app,
    environment: GLOBAL_CONFIG.ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    attachProps: true,
    trackComponents: true,
    logErrors: true,
    attachStacktrace: true,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          'localhost',
          'predprod-kong-dev.smartmed.pro',
          'ext.smartmed.pro',
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

app
  .use(router)
  .use(SmedInstallPlugin, defaultSmedInstallOptions)
  .use(store)
  .use(ThemePlugin)
  .mount('#app');
