<template>
  <root>
    <component :is="layout">
      <router-view />
    </component>
  </root>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, provide } from 'vue';
import { useRouter } from 'vue-router';

import { provideWidgetsUseRequestInstance } from '@admin/pressets/Widgets';
import { Root } from '@smartmed/ui/Root';
import { CUSTOM_ICONS_TOKEN, IS_POPUP_MOBILE_TOKEN } from '@smartmed/ui/tokens';
import { useMedia } from '@smartmed/ui/use';

import { useWireframe } from '@/libs/settings/useWireframe';

import { useKongRequest } from './bootstrap';

const router = useRouter();
const _ = useWireframe();
const { computedSmallerThen } = useMedia();

const isMobile = computedSmallerThen('landscape');

provide(IS_POPUP_MOBILE_TOKEN, isMobile);

provideWidgetsUseRequestInstance(useKongRequest);

provide(CUSTOM_ICONS_TOKEN, {
  dark: defineAsyncComponent(() => import('./assets/icons/dark.svg')),
  light: defineAsyncComponent(() => import('./assets/icons/light.svg')),
  android: defineAsyncComponent(() => import('./assets/icons/android.svg')),
  ios: defineAsyncComponent(() => import('./assets/icons/ios.svg')),
});

const layout = computed(() => {
  const value = router.currentRoute.value;

  return value.meta?.layout || 'div';
});
</script>
