import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const campaignsRoutes: RouteRecordRaw = {
  path: 'campaigns',
  name: ROUTES.salesToolsCampaigns.name,
  redirect: { name: 'salesToolsCampaignsList' },
  meta: {
    title: ROUTES.salesToolsCampaigns.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'salesToolsCampaignsList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.salesToolsCampaignsCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.salesToolsCampaignsCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.salesToolsCampaignsEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsCampaignsEdit.title,
        role: [],
      },
    },
  ],
};
