export const chronicles = {
  chronicles: {
    title: 'Хронические пациенты',
    name: 'Chronicles',
  },
  chroniclesQuiz: {
    title: 'Опросы',
    name: 'ChroniclesQuiz',
  },
  chroniclesQuizList: {
    title: 'Опросы',
    name: 'ChroniclesQuizList',
  },
  chroniclesQuizCreate: {
    title: 'Создание опроса',
    name: 'ChroniclesQuizCreate',
  },
  chroniclesQuizPreview: {
    title: 'Просмотр информации об опросе',
    name: 'ChroniclesQuizPreview',
  },
  chroniclesQuizEdit: {
    title: 'Редактирование опроса',
    name: 'ChroniclesQuizEdit',
  },
  chroniclesDoctors: {
    title: 'Врачи',
    name: 'ChroniclesDoctors',
  },
  chroniclesDoctorsList: {
    title: 'Врачи',
    name: 'ChroniclesDoctorsList',
  },
  chroniclesPatients: {
    title: 'Пациенты',
    name: 'ChroniclesPatients',
  },
  chroniclesPatientsList: {
    title: 'Пациенты',
    name: 'ChroniclesPatientsList',
  },
  chroniclesPatientsPreview: {
    title: 'Просмотр информации о пациенте',
    name: 'ChroniclesPatientsPreview',
  },
  chroniclesPatientsPreviewInfo: {
    title: 'Просмотр информации о пациенте',
    name: 'ChroniclesPatientsPreviewInfo',
  },
  chroniclesPatientsPreviewQuizzes: {
    title: 'Просмотр информации о пациенте - Опросы',
    name: 'ChroniclesPatientsPreviewQuizzes',
  },
  chroniclesPatientsPreviewPills: {
    title: 'Просмотр информации о пациенте - Препараты',
    name: 'ChroniclesPatientsPreviewPills',
  },
  chroniclesPatientsPreviewReferrals: {
    title: 'Просмотр информации о пациенте - Аналитика',
    name: 'ChroniclesPatientsPreviewReferrals',
  },
} as const;
