import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';

export const referralsRouteName = {
  updateReferrals: {
    title: 'Обновление направлений',
    name: 'UpdateReferrals',
  },
};

export const updateReferralsRoutes: RouteRecordRaw = {
  path: 'update-referrals',
  name: referralsRouteName.updateReferrals.name,
  component: () => import('./views/List.vue'),
  meta: {
    layout: LayoutDefault,
    title: referralsRouteName.updateReferrals.title,
    role: [roles.ReferralSupport],
  }
};
