import { RouteRecordRaw } from 'vue-router';

export const chroniclesRemindersRoutes: RouteRecordRaw = {
  path: 'reminders',
  name: 'ChorniclesReminders',
  component: () => import('./List.vue'),
  meta: {
    title: 'Памятки',
    role: [],
  },
  children: [
    {
      path: 'show/:id',
      name: 'ChorniclesRemindersShow',
      component: () => import('./ReminderPreview.vue'),
      props: (route) => ({ id: Number(route.params.id) }),
      meta: {
        role: [],
      },
    },
    {
      path: 'edit/:id',
      name: 'ChorniclesRemindersEdit',
      component: () => import('./ReminderEdit.vue'),
      props: (route) => ({ id: Number(route.params.id) }),
      meta: {
        role: [],
      },
    },
  ],
};
