import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const errorsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'errors',
    name: ROUTES.errors.name,
    meta: {
      title: ROUTES.errors.title,
      role: [roles.MarketplaceAdmin],
    },
    component: () => import('./Errors.vue'),
  },
];
