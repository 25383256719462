import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { appointment } from '@/application/Receptions/views/Appointment/routes';
import { useAuth } from '@/libs/authorization/useAuth';
import { getCurrentRole } from '@/libs/user/role/getCurrentRole';
import { useUserPermission } from '@/libs/user/role/useUserPermission';

import { menuSectionRoutes } from './menuSection/menuSection.routes';
import { ROUTES } from './routes';

const { getRoutesWithPermission } = useUserPermission();

const DEFAULT_NAVIGATION = appointment;

export function homeRouteResolve(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isAuth } = useAuth();

  const authorized = isAuth();

  if (!authorized) {
    next({
      name: ROUTES.signIn.name,
    });

    return;
  }

  const roles = getCurrentRole();
  const showBlockedRoutes = false;
  const routesWithPermission = getRoutesWithPermission(
    [DEFAULT_NAVIGATION],
    roles,
    showBlockedRoutes
  );

  const hasPermissionToDefault = routesWithPermission.length > 0;

  if (hasPermissionToDefault) {
    next({
      name: routesWithPermission[0].name,
    });

    return;
  }

  const bestGuess = getRoutesWithPermission(
    menuSectionRoutes,
    roles,
    showBlockedRoutes
  );

  if (bestGuess.length > 0) {
    next({
      name: bestGuess[0].name,
    });
  } else {
    next();
  }
}
