import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

import { citiesRoutes } from './views/Cities/routes';
import { clinicsRoutes } from './views/Clinics/routes';
import { doctorsRoutes } from './views/Doctors/routes';
import { featureToggleRoutes } from './views/FeatureToggle/routes';
import { microserviceConfigsRoutes } from './views/MicroserviceConfigs/routes';
import { scheduleGuideRoutes } from './views/ScheduleGuide/routes';

export const adminRoutes: RouteRecordRaw = {
  path: '/admin',
  name: ROUTES.admin.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.admin.title,
    role: [],
  },
  children: [
    featureToggleRoutes,
    clinicsRoutes,
    doctorsRoutes,
    citiesRoutes,
    scheduleGuideRoutes,
    microserviceConfigsRoutes,
    // documentsRoutes,
  ],
};
