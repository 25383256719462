import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

export const usersRoutes: RouteRecordRaw = {
  path: '/users',
  name: ROUTES.users.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.users.title,
    role: [roles.UsersAdmin],
  },
  children: [
    {
      path: 'list',
      name: ROUTES.usersList.name,
      component: () => import('./views/List/List.vue'),
      meta: {
        title: ROUTES.usersList.title,
        role: [],
      },
    },
    {
      path: 'list/:id',
      name: ROUTES.userCard.name,
      component: () => import('./views/User/User.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.userCard.title,
        role: [],
      },
    },
  ],
};
