import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const widgetsRoutes: RouteRecordRaw = {
  path: 'widgets',
  name: ROUTES.widgetsLaboratoriesServices.name,
  meta: {
    title: ROUTES.widgetsLaboratoriesServices.title,
    role: [roles.MarketplaceAdmin],
  },
  redirect: { name: 'WidgetsLaboratoriesServicesRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'WidgetsLaboratoriesServicesRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
  ],
};
