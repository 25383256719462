import { chronicles } from './chronicles';
import { receptionsSettings } from './receptionsSettings';

export const ROUTES = {
  home: {
    title: '',
    name: 'Home',
  },
  chronics: {
    title: 'Хроники',
    name: 'Chronics',
  },
  chronicsDoctors: {
    title: 'Врачи',
    name: 'ChronicsDoctors',
  },
  programs: {
    title: 'Программы',
    name: 'Programs',
  },
  products: {
    title: 'Продукты',
    name: 'Products',
  },
  addProduct: {
    title: 'Создание продукта',
    name: 'AddProduct',
  },
  editProduct: {
    title: 'Редактирование продукта',
    name: 'EditProduct',
  },
  principals: {
    title: 'Принципалы',
    name: 'Principals',
  },
  addPrincipal: {
    title: 'Создание принципала',
    name: 'AddPrincipal',
  },
  editPrincipal: {
    title: 'Редактирование принципала',
    name: 'EditPrincipal',
  },
  pageNotFound: {
    name: 'PageNotFound',
    title: 'Страница не найдена',
  },
  pageNoPermission: {
    name: 'PageNoPermission',
    title: 'Нет доступа к странице',
  },
  documents: {
    title: 'Документы',
    name: 'Documents',
  },
  addDocument: {
    title: 'Создание документа',
    name: 'AddDocument',
  },
  editDocument: {
    title: 'Редактирование документа',
    name: 'EditDocument',
  },
  sales: {
    title: 'Покупки',
    name: 'Sales',
  },
  errors: {
    title: 'Ошибки',
    name: 'Errors',
  },
  markers: {
    title: 'Маркеры',
    name: 'Markers',
  },
  promocodes: {
    title: 'Генератор кодов',
    name: 'Promocodes',
  },
  nonActivatedAttachments: {
    title: 'Неактивированные прикрепления',
    name: 'NonActivatedAttachments',
  },
  activatedAttachments: {
    title: 'Активированные прикрепления',
    name: 'ActivatedAttachments',
  },
  medicalPrograms: {
    title: 'Медпрограммы',
    name: 'MedicalPrograms',
  },
  addMedicalProgram: {
    title: 'Добавить программу',
    name: 'AddMedicalProgram',
  },
  editMedicalProgram: {
    title: 'Медицинская программа',
    name: 'EditMedicalProgram',
  },
  nonMedicalPrograms: {
    title: 'Немедпрограммы',
    name: 'NonMedicalPrograms',
  },
  addNonMedicalProgram: {
    title: 'Добавить программу',
    name: 'AddNonMedicalProgram',
  },
  editNonMedicalProgram: {
    title: 'Немедицинская программа',
    name: 'EditNonMedicalProgram',
  },
  signIn: {
    title: 'Вход',
    name: 'SignIn',
  },
  patient: {
    title: 'ЛК пациента',
    name: 'Patient',
  },
  patientDeposits: {
    title: 'Депозиты',
    name: 'deposits',
  },
  patientLaboratorySupermarketPayments: {
    title: 'Оплаты в СмартМед',
    name: 'ls-payments',
  },
  purposes: {
    title: 'Назначения',
    name: 'Purposes',
  },
  pharmacy: {
    title: 'Аптеки',
    name: 'Pharmacy',
  },
  pharmacyPayOrders: {
    title: 'Платежи',
    name: 'PayOrdersList',
  },
  pharmacyPayOrdersItemEdit: {
    title: 'Информация о платеже',
    name: 'PayOrdersItemEdit',
  },
  pharmacyClinics: {
    title: 'Клиники',
    name: 'PharmacyClinics',
  },
  pharmacyClinicsCreate: {
    title: 'Новая клиника',
    name: 'PharmacyClinicsCreate',
  },
  pharmacyClinicsEdit: {
    title: 'Редактирование клиники',
    name: 'PharmacyClinicsEdit',
  },
  pharmacyPartners: {
    title: 'Партнёры',
    name: 'PharmacyPartners',
  },
  pharmacyPartnersCreate: {
    title: 'Новый партнер',
    name: 'PharmacyPartnersCreate',
  },
  pharmacyPartnersEdit: {
    title: 'Редактирование партнера',
    name: 'PharmacyPartnersEdit',
  },
  pharmacyPickupPoints: {
    title: 'Пункты выдачи',
    name: 'PharmacyPickupPoints',
  },
  pharmacyPickupPointsCreate: {
    title: 'Новый пункт выдачи',
    name: 'PharmacyPickupPointsCreate',
  },
  pharmacyPickupPointsEdit: {
    title: 'Редактирование пункта выдачи',
    name: 'PharmacyPickupPointsEdit',
  },
  pharmacyProducers: {
    title: 'Производители',
    name: 'PharmacyProducers',
  },
  pharmacyProducersCreate: {
    title: 'Новый производитель',
    name: 'PharmacyProducersCreate',
  },
  pharmacyProducersEdit: {
    title: 'Редактирование производителя',
    name: 'PharmacyProducersEdit',
  },
  pharmacyPromotions: {
    title: 'Акции',
    name: 'PharmacyPromotions',
  },
  pharmacyPromotionsCreate: {
    title: 'Создание Акции',
    name: 'pharmacyPromotionsCreate',
  },
  pharmacyPromotionsEdit: {
    title: 'Редактирование Акции',
    name: 'pharmacyPromotionsEdit',
  },
  pharmacyInn: {
    title: 'ИНН',
    name: 'PharmacyInn',
  },
  pharmacyInnCreate: {
    title: 'Новый ИНН',
    name: 'PharmacyInnCreate',
  },
  pharmacyInnEdit: {
    title: 'Редактирование ИНН',
    name: 'PharmacyInnEdit',
  },
  pharmacyMedicalForm: {
    title: 'Медицинская форма',
    name: 'PharmacyMedicalForm',
  },
  pharmacyMedicalFormCreate: {
    title: 'Новый медицинская форма',
    name: 'PharmacyMedicalFormCreate',
  },
  pharmacyMedicalFormEdit: {
    title: 'Редактирование медицинской формы',
    name: 'PharmacyMedicalFormEdit',
  },
  pharmacyProducts: {
    title: 'Товары',
    name: 'PharmacyProducts',
  },
  pharmacyProductsCreate: {
    title: 'Новый товар',
    name: 'PharmacyProductsCreate',
  },
  pharmacyProductsEdit: {
    title: 'Редактирование товара',
    name: 'PharmacyProductsEdit',
  },
  pharmacyPartnerProducts: {
    title: 'Товары партнёров',
    name: 'PharmacyPartnerProducts',
  },
  pharmacyPartnerProductsCreate: {
    title: 'Новый товар партнера',
    name: 'PharmacyPartnerProductsCreate',
  },
  pharmacyPartnerProductsEdit: {
    title: 'Редактирование товара партнера',
    name: 'PharmacyPartnerProductsEdit',
  },
  pharmacyRegions: {
    title: 'Регионы',
    name: 'PharmacyRegions',
  },
  pharmacyRegionsCreate: {
    title: 'Новый регион',
    name: 'PharmacyRegionsCreate',
  },
  pharmacyRegionsEdit: {
    title: 'Редактирование региона',
    name: 'PharmacyRegionsEdit',
  },
  pharmacyPartnerRegions: {
    title: 'Регионы партнёров',
    name: 'PharmacyPartnerRegions',
  },
  pharmacyPartnerRegionsCreate: {
    title: 'Новый регион партнера',
    name: 'PharmacyPartnerRegionsCreate',
  },
  pharmacyPartnerRegionsEdit: {
    title: 'Редактирование региона партнера',
    name: 'PharmacyPartnerRegionsEdit',
  },
  pharmacyTags: {
    title: 'Теги',
    name: 'PharmacyTags',
  },
  pharmacyTagsCreate: {
    title: 'Новый тег',
    name: 'PharmacyTagsCreate',
  },
  pharmacyTagsEdit: {
    title: 'Редактирование тега',
    name: 'PharmacyTagsEdit',
  },
  pharmacyOrders: {
    title: 'Заказы',
    name: 'PharmacyOrder',
  },
  pharmacyOrderEdit: {
    title: 'Просмотр заказа',
    name: 'PharmacyOrderEdit',
  },
  pharmacyReviews: {
    title: 'Отзывы',
    name: 'PharmacyReviews',
  },
  pharmacyReviewCreate: {
    title: 'Новый отзыв',
    name: 'PharmacyReviewCreate',
  },
  pharmacyReviewEdit: {
    title: 'Изменение отзыва',
    name: 'PharmacyReviewEdit',
  },
  pharmacyBannerSlots: {
    title: 'Слоты для баннера',
    name: 'PharmacyBannerSlots',
  },
  pharmacyBannerSlotsEdit: {
    title: 'Редактирование слота',
    name: 'PharmacyBannerSlotsEdit',
  },
  pharmacyBannerSlotsCreate: {
    title: 'Создание слота',
    name: 'PharmacyBannerSlotsCreate',
  },
  pharmacyBanners: {
    title: 'Баннеры',
    name: 'PharmacyBanners',
  },
  pharmacyBannersEdit: {
    title: 'Изменение баннера',
    name: 'PharmacyBannersEdit',
  },
  pharmacyBannersCreate: {
    title: 'Новый баннер',
    name: 'PharmacyBannersCreate',
  },
  pharmacyComprasionOfGoods: {
    title: 'Сопоставление товаров',
    name: 'PharmacyComprasionOfGoods',
  },
  expert: {
    title: 'Эксперт',
    name: 'expert',
  },
  expertHomeCallServices: {
    title: 'Помощь на дому',
    name: 'ExpertHomeCallServices',
  },
  expertHomeCallConstructor: {
    title: 'Конструктор',
    name: 'ExpertHomeCallConstructor',
  },
  expertHomeCallConstructorCreate: {
    title: 'Разметка услуги',
    name: 'ExpertHomeCallConstructorCreate',
  },
  expertHomeCallLabeled: {
    title: 'Размеченные',
    name: 'ExpertHomeCallLabeled',
  },
  expertHomeCallLabeledEdit: {
    title: 'Изменение сервиса',
    name: 'ExpertHomeCallLabeledEdit',
  },
  expertPolygons: {
    title: 'Управление зонами',
    name: 'ExpertPolygons',
  },
  expertPolygonsEdit: {
    title: 'Редактирование зон',
    name: 'ExpertPolygonsEdit',
  },
  admin: {
    title: 'Админ',
    name: 'Admin',
  },
  adminFeatureToggle: {
    title: 'Рубильник',
    name: 'AdminFeatureToggle',
  },
  adminAddFeatureToggle: {
    title: 'Добавление рубильника',
    name: 'AdminAddFeatureToggle',
  },
  adminEditFeatureToggle: {
    title: 'Изменение рубльника',
    name: 'AdminEditFeatureToggle',
  },
  adminClinics: {
    title: 'Клиники',
    name: 'AdminClinics',
  },
  adminClinicsEdit: {
    title: 'Редактирование Клиники',
    name: 'AdminClinicsEdit',
  },
  adminDoctors: {
    title: 'Врачи',
    name: 'AdminDoctors',
  },
  adminCities: {
    title: 'Города',
    name: 'AdminCities',
  },
  adminCitiesEdit: {
    title: 'Редактирование города',
    name: 'AdminCitiesEdit',
  },
  adminCitiesCreate: {
    title: 'Создание города',
    name: 'AdminCitiesCreate',
  },
  adminLaboratoriesServices: {
    title: 'Лабораторные услуги',
    name: 'AdminLaboratoriesServices',
  },
  adminLaboratoriesServicesEdit: {
    title: 'Редактирование услуги',
    name: 'AdminLaboratoriesServicesEdit',
  },
  adminLaboratoriesServicesEditTags: {
    title: 'Редактирование тэгов услуги',
    name: ' AdminLaboratoriesServicesEditTags',
  },
  groupsLaboratoriesServices: {
    title: 'Группы',
    name: 'GroupsLaboratoriesServices',
  },
  groupLaboratoriesServices: {
    title: 'Добавить группу',
    name: 'GroupLaboratoriesServices',
  },
  editGroupLaboratoriesServices: {
    title: 'Редактирование группы',
    name: 'EditGroupLaboratoriesServices',
  },
  widgetsLaboratoriesServices: {
    title: 'Виджеты',
    name: 'WidgetsLaboratoriesServices',
  },
  additionalClinicsAttributes: {
    title: 'Дополнительные атрибуты клиник',
    name: 'AdditionalClinicsAttributes',
  },
  editAdditionalClinicsAttributes: {
    title: 'Редактирование атрибутов',
    name: 'EditAdditionalClinicsAttributes',
  },
  adminScheduleGuide: {
    title: 'Расписания',
    name: 'AdminScheduleGuide',
  },
  receptions: {
    title: 'Записи',
    name: 'Receptions',
  },
  appointment: {
    title: 'Записи',
    name: 'Appointment',
  },
  appointmentPreview: {
    title: 'Запись',
    name: 'AppointmentPreview',
  },
  receptionsSchedule: {
    title: 'Сетка расписания',
    name: 'ReceptionsSchedule',
  },
  receptionsExceptionalEvents: {
    title: 'Исключительные события',
    name: 'ReceptionsExceptionalEvents',
  },
  receptionsExceptionalEventsEdit: {
    title: 'Редактирование события',
    name: 'ReceptionsExceptionalEdit',
  },
  receptionsDoctors: {
    title: 'Популярные врачи',
    name: 'ReceptionsDoctors',
  },
  receptionsAddDoctor: {
    title: 'Добавление популярного врача',
    name: 'ReceptionsAddDoctor',
  },
  receptionsPopularSpecialties: {
    title: 'Популярные специальности',
    name: 'ReceptionsPopularSpecialties',
  },
  receptionsPopularSpecialtiesCreate: {
    title: 'Новая специальность',
    name: 'ReceptionsPopularSpecialtiesCreate',
  },
  receptionsPopularClinics: {
    title: 'Популярные клиники',
    name: 'ReceptionsPopularClinics',
  },
  receptionsPopularClinicsCreate: {
    title: 'Добавить клинику',
    name: 'ReceptionsPopularClinicsCreate',
  },
  specializationsInfoclinic: {
    title: 'Специальности',
    name: 'SpecializationsInfoclinic',
  },
  receptionsHistoryList: {
    title: 'История записей',
    name: 'receptionsHistoryList',
  },
  receptionsHistoryPreview: {
    title: 'Просмотр истории записи',
    name: 'receptionsHistoryPreview',
  },
  receptionTypes: {
    title: 'Виды приёма',
    name: 'ReceptionTypes',
  },
  receptionTypesEdit: {
    title: 'Изменения вида приёма',
    name: 'ReceptionTypesEdit',
  },
  receptionBusinessGroup: {
    title: 'Бизнес группы',
    name: 'ReceptionBusinessGroup',
  },
  receptionBusinessGroupCreate: {
    title: 'Добавление бизнес группы',
    name: 'ReceptionBusinessGroupCreate',
  },
  receptionBusinessGroupEdit: {
    title: 'Редактирование бизнес группы',
    name: 'ReceptionBusinessGroupEdit',
  },
  receptionSynchronizationClinics: {
    title: 'Синхронизация клиник',
    name: 'ReceptionSynchronizationClinics',
  },
  receptionsClinicsCashSlots: {
    title: 'Клиники с кассовыми слотами',
    name: 'ReceptionsClinicsCashSlots',
  },
  receptionsClinicsCashSlotsCreate: {
    title: 'Создание клиники с кассовыми слотами',
    name: 'ReceptionsClinicsCashSlotsCreate',
  },
  receptionsClinicsCashSlotsEdit: {
    title: 'Редактирование клиники с кассовыми слотами',
    name: 'ReceptionsClinicsCashSlotsEdit',
  },
  receptionsDiagnostics: {
    title: 'Исследования',
    name: 'ReceptionsDiagnostics',
  },
  receptionsDiagnosticEdit: {
    title: 'Редактирование исследования',
    name: 'ReceptionsDiagnosticEdit',
  },
  salesTools: {
    title: 'Sales Tools',
    name: 'SalesTools',
  },
  salesToolsCampaigns: {
    title: 'Кампании',
    name: 'SalesToolsCampaigns',
  },
  salesToolsCampaignsEdit: {
    title: 'Редактирование кампании',
    name: 'SalesToolsCampaignsEdit',
  },
  salesToolsCampaignsCreate: {
    title: 'Добавление кампании',
    name: 'SalesToolsCampaignsCreate',
  },
  salesToolsAdvertisements: {
    title: 'Объявления',
    name: 'SalesToolsAdvertisements',
  },
  salesToolsAdvertisementsEdit: {
    title: 'Редактирование объявления',
    name: 'SalesToolsAdvertisementsEdit',
  },
  salesToolsAdvertisementsCreate: {
    title: 'Добавление объявления',
    name: 'SalesToolsAdvertisementsCreate',
  },
  salesToolsSlots: {
    title: 'Слоты',
    name: 'SalesToolsSlots',
  },
  salesToolsSlotsEdit: {
    title: 'Редактирование слота',
    name: 'SalesToolsSlotsEdit',
  },
  salesToolsSlotsCreate: {
    title: 'Добавление слота',
    name: 'SalesToolsSlotsCreate',
  },
  salesToolsDistribution: {
    title: 'Распределение',
    name: 'SalesToolsDistribution',
  },
  salesToolsDistributionEdit: {
    title: 'Редактирование распределения',
    name: 'SalesToolsDistributionEdit',
  },
  salesToolsDistributionCsv: {
    title: 'Загрузка распределения из csv-файла',
    name: 'SalesToolsDistributionCsv',
  },
  salesToolsDistributionResetStatuses: {
    title: 'Сброс счетчика',
    name: 'SalesToolsDistributionResetStatuses',
  },
  salesToolsDistributionCreate: {
    title: 'Новое распределение',
    name: 'SalesToolsDistributionCreate',
  },
  salesToolsUsers: {
    title: 'Пользователи',
    name: 'SalesToolsUsers',
  },
  salesToolsHistory: {
    title: 'История',
    name: 'SalesToolsHistory',
  },
  salesToolsHistoryList: {
    title: 'История',
    name: 'salesToolsHistoryList',
  },
  deposits: {
    title: 'Депозиты',
    name: 'Deposits',
  },
  depositsPaymentsHistory: {
    title: 'История пополнений',
    name: 'DepositsPaymentsHistory',
  },
  depositsUsersDeposits: {
    title: 'Депозиты пользователей',
    name: 'DepositsUsersDeposits',
  },
  settings: {
    title: 'Настройки',
    name: 'Settings',
  },
  settingsRoles: {
    title: 'Проверка ролей',
    name: 'SettingsRoles',
  },
  payments: {
    title: 'Оплаты',
    name: 'Payments',
  },
  paymentsSources: {
    title: 'Источники',
    name: 'PaymentsSources',
  },
  paymentsSourcesAdd: {
    title: 'Добавление источника',
    name: 'PaymentsSourcesAdd',
  },
  paymentsCompanies: {
    title: 'Юрлица',
    name: 'PaymentsCompanies',
  },
  paymentsCompaniesAdd: {
    title: 'Добавление юрлица',
    name: 'PaymentsCompaniesAdd',
  },
  paymentsMerchants: {
    title: 'Мерчанты',
    name: 'PaymentsMerchants',
  },
  paymentsMerchantsAdd: {
    title: 'Добавление мерчанта',
    name: 'PaymentsMerchantsAdd',
  },
  paymentsSettings: {
    title: 'Настройка оплат',
    name: 'PaymentsSettings',
  },
  paymentsTransactions: {
    title: 'Транзакции',
    name: 'PaymentsTransactions',
  },
  paymentsSettingsAdd: {
    title: 'Добавление настройки оплат',
    name: 'PaymentsSettingsAdd',
  },
  paymentsFiscalEndpoints: {
    title: 'Фиксальный провайдер',
    name: 'PaymentsFiscalEndpoints',
  },
  paymentsFiscalEndpointsAdd: {
    title: 'Добавление фиксального провайдера',
    name: 'PaymentsFiscalEndpointsAdd',
  },
  paymentsPaymentEndpoints: {
    title: 'Платежный провайдер',
    name: 'PaymentsPaymentEndpoints',
  },
  paymentsPaymentEndpointsAdd: {
    title: 'Добавление платежного провайдера',
    name: 'PaymentsPaymentEndpointsAdd',
  },
  paymentsReceipts: {
    title: 'Нефискализированные чеки',
    name: 'PaymentsReceipts',
  },
  paymentsReceiptPreview: {
    title: 'Просмотр чека',
    name: 'PaymentsReceiptPreview',
  },
  paymentsOrders: {
    title: 'Заказы',
    name: 'PaymentsOrders',
  },
  paymentsOrderPreview: {
    title: 'Просмотр заказа',
    name: 'PaymentOrderPreview',
  },
  paymentsOfflinePayments: {
    title: 'Оплаты оффлайн и онлайн записи',
    name: 'offline-payments',
  },
  dynamicsIndicators: {
    title: 'Динамика показателей',
    name: 'DynamicsIndicators',
  },
  adminDocuments: {
    title: 'Документы',
    name: 'AdminDocuments',
  },
  adminDocumentsList: {
    title: 'Документы',
    name: 'AdminDocumentsList',
  },
  adminDocumentEdit: {
    title: 'Редактирование документа',
    name: 'AdminDocumentEdit',
  },
  adminDocumentCreate: {
    title: 'Создание документа',
    name: 'AdminDocumentCreate',
  },
  users: {
    title: 'Пользователи',
    name: 'Users',
  },
  usersList: {
    title: 'Список пользователей',
    name: 'UsersList',
  },
  userCard: {
    title: 'Карточка пользователя',
    name: 'UserCard',
  },

  ...receptionsSettings,
  ...chronicles,
} as const;
