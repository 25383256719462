import { createUseRequest } from '@smartmed/http/useRequest';
import {
  createAccessTokenInterceptor,
  createInterceptor,
} from '@smartmed/http/useRequest/interceptors';
import { AxiosRequestConfig } from 'axios';

import { Authorization } from '@/libs/authorization/Authorization';
import { Axios, Http } from '@/libs/http';

import router from './router';
import { ROUTES } from './router/routes';

const axios = new Axios();
const http = new Http(axios, GLOBAL_CONFIG.API_ENDPOINT);

export const authorization = new Authorization();

const refreshTokenHandler = () =>
  authorization
    .refreshTokenAndSave()
    .then((token) => (token ? token.data.access_token : Promise.reject(null)));

const afterRefreshFailure = () => {
  authorization.signOut();

  return router.push({
    name: ROUTES.signIn.name,
    query: {
      nextUrl: router.currentRoute.value.fullPath,
    },
  });
};

const oldInstance = axios.getInstance();

const accessTokenInterceptor = createAccessTokenInterceptor<{
  responseType?: string;
}>(authorization.tokenStorage, refreshTokenHandler, afterRefreshFailure);

accessTokenInterceptor(oldInstance.interceptors as any, oldInstance as any);

const appVersionInterceptor = createInterceptor((interceptor) => {
  interceptor.request.use((conf) => {
    const config = conf as AxiosRequestConfig;
    const headers = config.headers || {};

    config.headers = {
      ...headers,
      'Application-Version': '2.2.0',
    };

    return config;
  });
});

const isProbablyAxiosResponse = (response: any) => {
  return 'request' in response && response.request instanceof XMLHttpRequest;
};

const throwDotNetErrorInterceptor = createInterceptor((interceptor) => {
  interceptor.response.use((response) => {
    const data = isProbablyAxiosResponse(response) ? response.data : response;

    if (data.StatusInfo && data.StatusInfo.Status === 'ERROR') {
      return Promise.reject({ response });
    }

    return response;
  });
});

// todo: fix in library and bump version here
export const forceInferDataInterceptor = createInterceptor((interceptor) => {
  interceptor.response.use(
    (response) =>
      isProbablyAxiosResponse(response) ? response.data : response,
    (error) => Promise.reject(error)
  );
});

export const useKongRequest = createUseRequest<
  { responseType?: string },
  { inferData: true }
>(GLOBAL_CONFIG.API_ENDPOINT, [
  accessTokenInterceptor,
  forceInferDataInterceptor,
]);

export const useK8SRequest = createUseRequest<{}, { inferData: true }>(
  GLOBAL_CONFIG.API_K8S_ENDPOINT,
  [accessTokenInterceptor, forceInferDataInterceptor]
);

export const usePartnersRequest = createUseRequest<{}, { inferData: true }>(
  GLOBAL_CONFIG.CLIENT_API_ENDPOINT,
  [accessTokenInterceptor, forceInferDataInterceptor]
);

export const useDotNetAdminRequest = createUseRequest<{}, { inferData: true }>(
  `${GLOBAL_CONFIG.API_ENDPOINT}${GLOBAL_CONFIG.ADMIN_KONG_ENDPOINT_PREFIX}`,
  [accessTokenInterceptor, forceInferDataInterceptor]
);

export const useDotNetRequest = createUseRequest<{}, { inferData: true }>(
  GLOBAL_CONFIG.DOTNET_API_ENDPOINT,
  [
    accessTokenInterceptor,
    appVersionInterceptor,
    throwDotNetErrorInterceptor,
    forceInferDataInterceptor,
  ]
);

export const useTelemedRequest = createUseRequest<
  { responseType?: string },
  { inferData: true }
>(GLOBAL_CONFIG.TELEMED_API_ENDPOINT, [
  accessTokenInterceptor,
  forceInferDataInterceptor,
]);

export const getAuthorizationHeader = () => {
  const token = authorization.tokenStorage.get();

  return token ? { Authorization: `Bearer ${token}` } : {};
};

export { http };
