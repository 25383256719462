import { useAlerts } from '@smartmed/ui/use/alerts';

import { authorization } from '@/bootstrap';
import { SignInRequest } from '@/libs/authorization/types';

export function useAuth() {
  const notification = useAlerts();

  const signIn = async (form: SignInRequest) => {
    return authorization.signIn(form).catch(() => {
      notification.show('Проверьте введенные данные', {
        type: 'error',
      });

      return null;
    });
  };

  const isAuth = () => authorization.hasToken();

  const signOut = () => authorization.signOut();

  return {
    signIn,
    signOut,
    isAuth,
  };
}
