export const LabRouteNames = {
  lab: {
    title: 'Лаб.супермаркет',
    name: 'Lab',
  },
  invoiceToggle: {
    title: 'Переключатель счетов',
    name: 'InvoceToggle',
  },
} as const;
