import { LSPaymentsRoutes } from '@/application/Payments/views/LSPayments/routes';
import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

import { paymentsCompaniesRoutes } from './views/Companies/routes';
import { paymentsFiscalEndpointsRoutes } from './views/FiscalEndpoints/routes';
import { paymentsMerchantsRoutes } from './views/Merchants/routes';
import { paymentsOfflinePaymentsRoutes } from './views/OfflinePayments/routes';
import { paymentsOrdersRoutes } from './views/Orders/routes';
import { paymentsPaymentEndpointsRoutes } from './views/PaymentEndpoints/routes';
import { paymentsReceiptsRoutes } from './views/Receipts/routes';
import { paymentsSettingsRoutes } from './views/Settings/routes';
import { paymentsSourceRoutes } from './views/Source/routes';
import { paymentsTransactionsRoutes } from './views/Transactions/routes';

export const paymentsRoutes = {
  path: '/payments',
  name: ROUTES.payments.name,
  redirect: { name: paymentsOrdersRoutes.name },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    layout: LayoutDefault,
    title: ROUTES.payments.title,
    role: [roles.PaymentproviderAdmin],
  },
  children: [
    paymentsOrdersRoutes,
    paymentsTransactionsRoutes,
    paymentsSourceRoutes,
    paymentsCompaniesRoutes,
    paymentsMerchantsRoutes,
    paymentsSettingsRoutes,
    paymentsFiscalEndpointsRoutes,
    paymentsPaymentEndpointsRoutes,
    paymentsReceiptsRoutes,
    paymentsOfflinePaymentsRoutes,
    LSPaymentsRoutes,
  ],
};
