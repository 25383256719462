import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const paymentsOfflinePaymentsRoutes = {
  path: 'offline-payments',
  name: ROUTES.paymentsOfflinePayments.name,
  meta: {
    title: ROUTES.paymentsOfflinePayments.title,
    role: [roles.PaymentproviderSupport],
  },
  component: () => import('./List.vue'),
};
