import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';

import { telemedRouteNames } from './routeNames';

export const telemedRoutes: RouteRecordRaw = {
  path: '/telemed',
  name: telemedRouteNames.root.name,
  redirect: { name: telemedRouteNames.consultations.name },
  component: () => import('@/libs/layouts/Simple.vue'),
  meta: {
    layout: LayoutDefault,
    title: telemedRouteNames.root.title,
    role: [roles.ReceptionAdmin],
  },
  children: [
    {
      path: 'consultations',
      name: telemedRouteNames.consultations.name,
      meta: {
        title: telemedRouteNames.consultations.title,
        role: [],
      },
      component: () => import('@/libs/layouts/Simple.vue'),
      redirect: { name: 'TmdConsultationsRoot' },
      children: [
        {
          path: '',
          name: 'TmdConsultationsRoot',
          meta: {
            role: [],
          },
          component: () => import('./views/Consultations/Consultations.vue'),
        },
        {
          path: ':id',
          name: telemedRouteNames.consultationItem.name,
          meta: {
            title: 'Консультация',
            role: [],
          },
          props: (route) => ({ id: route.params.id }),
          component: () => import('./views/Consultations/ConsultationItem.vue'),
        },
      ],
    },
    {
      path: 'activity',
      name: telemedRouteNames.activity.name,
      meta: {
        title: telemedRouteNames.activity.title,
        role: [],
      },
      component: () => import('./views/Activity/Activity.vue'),
    },
    {
      path: 'report',
      name: telemedRouteNames.report.name,
      meta: {
        title: telemedRouteNames.report.title,
        role: [],
      },
      component: () => import('./views/Report/Report.vue'),
    },
    {
      path: 'schedule',
      name: telemedRouteNames.schedule.name,
      meta: {
        title: telemedRouteNames.schedule.title,
        role: [],
      },
      component: () => import('./views/Schedule/Schedule.vue'),
    },
  ],
};
