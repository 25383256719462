import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const receptionsHistory: RouteRecordRaw = {
  path: 'history',
  name: ROUTES.receptionsHistoryList.name,
  redirect: { name: 'receptionsHistoryRoot' },
  meta: {
    title: ROUTES.receptionsHistoryList.title,
    role: [roles.ReceptionView],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'receptionsHistoryRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: ':id',
      name: ROUTES.receptionsHistoryPreview.name,
      meta: {
        role: [],
        title: ROUTES.receptionsHistoryPreview.title,
      },
      component: () => import('./Preview.vue'),

      props: (route) => ({ id: route.params.id }),
    },
  ],
};
