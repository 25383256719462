import { RouteRecordRaw } from 'vue-router';

import { adminRoutes } from '@/application/Admin/adminRoutes';
import { chroniclesRoutes } from '@/application/Chronicles/routes';
import { contentManagerRoute } from '@/application/ContentManager/route';
import { depositsRoutes } from '@/application/Deposits/routes';
import { dynamicsIndicatorsRoutes } from '@/application/DynamicsIndicators/routes';
import { expertRoutes } from '@/application/Expert/routes';
import { labRoutes } from '@/application/Lab/routes';
import { notificationsRoutes } from '@/application/Notifications/routes';
import { patientRoutes } from '@/application/Patient/routes';
import { paymentsRoutes } from '@/application/Payments/routes';
import { pharmacyRoutes } from '@/application/Pharmacy/routes';
import { programsRoutes } from '@/application/Programs/routes';
import { receptionsRoutes } from '@/application/Receptions';
import { salesTools } from '@/application/SalesTools/routes';
import { telemedRoutes } from '@/application/Telemed/routes';
import { usersRoutes } from '@/application/Users/routes';

export const menuSectionRoutes: ReadonlyArray<RouteRecordRaw> = [
  usersRoutes,
  telemedRoutes,
  programsRoutes,
  patientRoutes,
  pharmacyRoutes,
  receptionsRoutes,
  expertRoutes,
  adminRoutes,
  labRoutes,
  salesTools,
  depositsRoutes,
  paymentsRoutes,
  contentManagerRoute,
  notificationsRoutes,
  dynamicsIndicatorsRoutes,
  chroniclesRoutes,
];
