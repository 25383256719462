import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

import { appointment } from './views/Appointment/routes';
import { businessGroup } from './views/BusinessGroup/routes';
import { cashSlotsRoutes } from './views/CashSlots/routes';
import { diagnosticsRoutes } from './views/Diagnostics/routes';
import { doctors } from './views/Doctors/routes';
import { exceptionalEvents } from './views/ExceptionalEvents/routes';
import { receptionsHistory } from './views/History/routes';
import { popularClinics } from './views/PopularClinics/routes';
import { popularSpecialties } from './views/PopularSpecialties/routes';
import { receptionTypes } from './views/ReceptionTypes/routes';
import { schedule } from './views/Schedule/routes';
import { receptionsSettingsRoutes } from './views/Settings/route';
import { specialtiesInfoclinic } from './views/Specializations/routes';
import { synchronizationClinics } from './views/SynchronizationClinics/routes';

export const receptionsRoutes: RouteRecordRaw = {
  path: '/receptions',
  name: ROUTES.receptions.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.receptions.title,
    role: [],
  },
  children: [
    appointment,
    receptionsHistory,
    schedule,
    exceptionalEvents,
    doctors,
    popularSpecialties,
    popularClinics,
    receptionTypes,
    businessGroup,
    receptionsSettingsRoutes,
    synchronizationClinics,
    cashSlotsRoutes,
    diagnosticsRoutes,
    specialtiesInfoclinic,
  ],
};
