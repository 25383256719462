import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { settingsRoutes } from '@/application/Settings/routes';
import { routerGuard } from '@/libs/authorization/guards/routerGuard';
import { routerLoginGuard } from '@/libs/authorization/guards/routerLoginGuard';
import { LayoutDefault, LayoutLogin } from '@/libs/layouts';

import { homeRouteResolve } from './homeRouteResolve';
import { menuSectionRoutes } from './menuSection/menuSection.routes';
import { ROUTES } from './routes';

export const allRoutes: Array<RouteRecordRaw> = (
  [
    {
      path: '/',
      name: ROUTES.home.name,
      meta: {
        layout: LayoutDefault,
      },
      component: () => import('@/application/Home.vue'),
      beforeEnter: homeRouteResolve,
    },
    {
      path: '/sign-in',
      name: ROUTES.signIn.name,
      meta: {
        layout: LayoutLogin,
      },
      component: () => import('@/application/SignIn/SignIn.vue'),
      beforeEnter: routerLoginGuard,
      props: (route: any) => ({
        nextUrl: route.query.nextUrl,
      }),
    },
    {
      path: '/no-permission',
      name: ROUTES.pageNoPermission.name,
      meta: {
        layout: LayoutDefault,
      },
      component: () => import('@/application/NoPermission/NoPermission.vue'),
      props: (route: any) => ({
        routeName: route.params.routeName,
        requiredRoles: route.params.requiredRoles,
      }),
    },
    {
      path: '/not-found',
      alias: '/:catchAll(.*)*',
      name: ROUTES.pageNotFound.name,
      meta: {
        layout: LayoutDefault,
      },
      component: () => import('@/application/PageNotFound/PageNotFound.vue'),
    },
    settingsRoutes,
  ] as any
).concat(menuSectionRoutes);

const router = createRouter({
  history: createWebHistory(GLOBAL_CONFIG.BASE_URL),
  routes: allRoutes,
});

router.onError(
  (error: { name: string; message: string }, to: { fullPath: string }) => {
    const dynamicallyImportsError =
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed');

    if (error.name === 'ChunkLoadError' || dynamicallyImportsError) {
      location.href = to.fullPath;
    }

    console.error(error);
  }
);

router.beforeEach((to, from, next) => {
  routerGuard(to, from, next);
});

export default router;
