import { ref, watch } from 'vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

import { distinct } from '@admin/shared';
import { flatten } from '@admin/shared';

import { Role } from '@/libs/user/role/roles';
import { allRoutes } from '@/router';
import { getRouteNameRolesDict } from '@/router/menuSection/getRouteNameRolesDict';

import { hasRoleAccess, resolveRouteByRoles } from './roleResolvers';

const ROUTE_PERMISSION_LOCALSTORAGE_KEY = '__smed-admin-route-persmission';

const cachedRouteNameRolesDict = (() => {
  let cache: ReturnType<typeof getRouteNameRolesDict> | null = null;

  return (...routes: Parameters<typeof getRouteNameRolesDict>) => {
    if (cache) {
      return cache;
    }

    const result = getRouteNameRolesDict(...routes);

    cache = result;

    return result;
  };
})();

/**
 * todo: Необходимо сильно оптимизировать эту логику
 */
export const useUserPermission = (function () {
  const localWireframe = localStorage.getItem(
    ROUTE_PERMISSION_LOCALSTORAGE_KEY
  );
  const isExist = !!localWireframe && localWireframe === '1';

  const showBlockedRoutes = ref(isExist);

  return () => {
    watch(
      showBlockedRoutes,
      (value) => {
        localStorage.setItem(
          ROUTE_PERMISSION_LOCALSTORAGE_KEY,
          value ? '1' : '0'
        );
      },
      { immediate: true }
    );

    const getRoutesWithPermission = (
      routes: ReadonlyArray<RouteRecordRaw>,
      roles: ReadonlyArray<Role | string>,
      showBlockedRoutes: boolean
    ) => {
      return resolveRouteByRoles(routes, roles, showBlockedRoutes);
    };

    const hasPermission = (
      route: RouteLocationNormalized,
      roles: ReadonlyArray<Role>
    ): boolean => {
      if (!route.name) {
        console.error(`Нет названия у ${route.name}`);

        return false;
      }

      return hasPermissionByRouteName(route.name as string, roles);
    };

    const getRequiredRoles = (route: RouteLocationNormalized) => {
      const allRoles = route.matched.map(
        (matched) => (matched.meta && (matched.meta.role as string[])) || []
      );
      const flattenRoles = flatten(allRoles);
      const uniqRoles = distinct(flattenRoles);

      return uniqRoles;
    };

    const hasPermissionByRouteName = (
      name: string,
      roles: ReadonlyArray<Role>
    ) => {
      const dict = cachedRouteNameRolesDict(allRoutes);

      if (!dict[name]) {
        console.error(`Не известно про роут ${name}`);

        return false;
      }

      return (
        dict[name].length === 0 ||
        dict[name].every((role) => hasRoleAccess(role, roles))
      );
    };

    return {
      getRequiredRoles,
      showBlockedRoutes,
      getRoutesWithPermission,
      hasPermission,
      hasRoleAccess,
      hasPermissionByRouteName,
    };
  };
})();
