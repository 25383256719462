import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { smartRedirect } from '@/libs/user/role/smartRedirect';
import { ROUTES } from '@/router/routes';

import { referralsRoutes } from './views/Referrals/routes';
import { updateReferralsRoutes } from './views/UpdateReferrals/routes';

export const patientRoutes: RouteRecordRaw = {
  path: '/patient',
  name: ROUTES.patient.name,
  component: () => import('@/libs/layouts/Simple.vue'),
  redirect: smartRedirect,
  meta: {
    layout: LayoutDefault,
    title: ROUTES.patient.title,
    role: [],
  },
  children: [
    {
      path: 'purposes',
      name: ROUTES.purposes.name,
      meta: {
        title: ROUTES.purposes.title,
        role: [roles.ConnectorAdmin],
      },
      component: () => import('@/application/Patient/views/Purposes/List.vue'),
    },
    {
      path: 'deposits',
      name: ROUTES.patientDeposits.name,
      meta: {
        title: ROUTES.patientDeposits.title,
        role: [roles.AccountAdmin],
      },
      component: () => import('@/application/Patient/views/Deposits/List.vue'),
    },
    referralsRoutes,
    updateReferralsRoutes,
  ],
};
