import { inject, provide } from 'vue';

import { createUseRequest } from '@smartmed/http/useRequest';

type Instance = ReturnType<typeof createUseRequest<any, any>>;

const KEY = '__widgetsUseRequestInstance';

export function provideWidgetsUseRequestInstance(instance: Instance) {
  provide(KEY, instance);
}

export function injectWidgetsUseRequestInstance(): Instance {
  const value = inject(KEY, null);

  if (value === null) {
    throw new Error(
      'you should provide useRequest instance with provideWidgetsUseRequestInstance'
    );
  }

  return value;
}
