import { RouteRecordRaw } from 'vue-router';

import { LayoutDefault } from '@/libs/layouts';
import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const dynamicsIndicatorsRoutes: RouteRecordRaw = {
  path: '/dynamics-indicators',
  name: ROUTES.dynamicsIndicators.name,
  component: () => import('@/application/NoPermission/NoPermission.vue'),
  beforeEnter(to, from, next) {
    window.open(GLOBAL_CONFIG.LINK_DYNAMICS_INDICATORS, '_blank');
    next(from);
  },
  meta: {
    layout: LayoutDefault,
    title: ROUTES.dynamicsIndicators.title,
    role: [roles.AnalysesDynamicAdmin],
  },
};
