import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const principalsRoutes: RouteRecordRaw = {
  path: 'principals',
  name: ROUTES.principals.name,
  meta: {
    title: ROUTES.principals.title,
    role: [roles.MarketplaceAdmin],
  },
  redirect: { name: 'ProgramsPrincipalsRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'ProgramsPrincipalsRoot',
      meta: {
        role: [],
      },
      component: () => import('./Principals.vue'),
    },
    {
      path: 'add',
      name: ROUTES.addPrincipal.name,
      meta: {
        title: ROUTES.addPrincipal.title,
        role: [],
      },
      component: () => import('./Principal.vue'),
    },
    {
      path: ':id',
      name: ROUTES.editPrincipal.name,
      meta: {
        title: ROUTES.editPrincipal.title,
        role: [],
      },
      props: (route) => ({ id: route.params.id }),
      component: () => import('./Principal.vue'),
    },
  ],
};
