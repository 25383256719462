import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const schedule: RouteRecordRaw = {
  path: 'schedule',
  name: ROUTES.receptionsSchedule.name,
  redirect: { name: 'scheduleList' },
  meta: {
    title: ROUTES.receptionsSchedule.title,
    role: [roles.ReceptionView],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'scheduleList',
      meta: {
        role: [],
      },
      component: () => import('./Schedule.vue'),
    },
  ],
};
