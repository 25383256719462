import { createUseRequest } from '@smartmed/http/useRequest';
import { createLocalStorageTokenStorage } from '@smartmed/http/useRequest/storage';

import { SignInRequest, SignInResponse } from './types';

export const ACCESS_TOKEN_NAME = '__smed-admin-access-token';
export const REFRESH_TOKEN_NAME = '__smed-admin-refresh-token';

export class Authorization {
  private readonly refreshTokenStorage =
    createLocalStorageTokenStorage(REFRESH_TOKEN_NAME);

  readonly tokenStorage = createLocalStorageTokenStorage(ACCESS_TOKEN_NAME);

  private readonly useUnauthRequest = createUseRequest(
    GLOBAL_CONFIG.CLIENT_API_ENDPOINT
  );

  readonly refreshToken = this.useUnauthRequest.post<
    () => { access_token: string; refresh_token: string }
  >('/auth/refresh/', () => `refresh_token=${this.refreshTokenStorage.get()}`, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    disableAbortOnRequest: true,
  });

  readonly refreshTokenAndSave = () => {
    return this.refreshToken().then((response) => {
      if (response?.data.refresh_token) {
        this.refreshTokenStorage.set(response.data.refresh_token);
        this.tokenStorage.set(response.data.access_token);
      }

      return response;
    });
  };

  readonly signIn = (data: SignInRequest) => {
    return this.useUnauthRequest
      .post<(data: SignInRequest) => SignInResponse>(
        '/auth/token/',
        (value) => `username=${value.username}&password=${value.password}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )(data)
      .then((response) => {
        if (response?.data?.access_token) {
          this.tokenStorage.set(response.data.access_token);
          this.refreshTokenStorage.set(response.data.refresh_token);
        }
      });
  };

  signOut() {
    this.tokenStorage.delete();
    this.refreshTokenStorage.delete();
  }

  hasToken() {
    return !!this.tokenStorage.get();
  }
}
