import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const popularClinics: RouteRecordRaw = {
  path: 'popular-clinics',
  name: ROUTES.receptionsPopularClinics.name,
  redirect: { name: 'receptionsPopularClinicsList' },
  meta: {
    title: ROUTES.receptionsPopularClinics.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'receptionsPopularClinicsList',
      meta: {
        role: [roles.ReceptionView],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.receptionsPopularClinicsCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.receptionsPopularClinicsCreate.title,
        role: [roles.ReceptionAdd],
      },
    },
  ],
};
