<template>
  <div :class="$style.container">
    <navigation-bar :sections="sections" :class="$style.navigation">
      <template #header>
        <div :class="$style.header">
          <router-link to="/">
            <svg-icon name="smartmed-inline" :size="[151, 32]" />
          </router-link>

          <BaseButton
            color="secondary"
            size="sm"
            :icon="theme === 'dark' ? 'light' : 'dark'"
            @click="theme === 'dark' ? (theme = 'light') : (theme = 'dark')"
          />
        </div>
      </template>

      <template #footer>
        <div :class="$style.footer">
          <div :class="['smed-space_bottom-3', $style.avatarContainer]">
            <avatar
              size="md"
              :name="username"
              :class="['smed-space_right-2', $style.avatar]"
            />

            <span class="smed-text_body-md smed-text_medium">{{
              username
            }}</span>
          </div>

          <base-button
            color="grey"
            class="smed-space_bottom-3"
            style="width: 100%"
            icon="settings"
            :to="{ name: 'Settings' }"
          >
            Настройки
          </base-button>

          <base-button
            color="error"
            icon="exit"
            style="width: 100%"
            @click="signOut"
          >
            Выйти
          </base-button>
        </div>
      </template>
    </navigation-bar>

    <scrollbar :class="$style.content">
      <Navbar @shown="navbarShown = $event" />

      <div class="smeda-container">
        <div :class="$style.pageContent" :data-navbar="navbarShown">
          <back-button class="smed-space_bottom-4" />

          <breadcrumbs
            v-if="filteredBreadcrumbs.length > 0"
            v-slot="{ item }"
            :items="filteredBreadcrumbs"
            class="smed-space_bottom-5"
          >
            {{ item.title }}
          </breadcrumbs>

          <div
            v-if="currentRoute"
            :class="['smed-space_bottom-4', $style.title]"
          >
            <h1 class="smed-text_h2">
              {{ currentRoute.title }}
            </h1>

            <base-button
              v-if="allowFavorite"
              color="secondary"
              class="smed-space_left-4"
              :icon="favoriteSelected ? 'star-filled' : 'star'"
              @click="onFavorite(currentRoute)"
            />

            <Pivot
              id="layoutDefaultActionPivot"
              :class="$style.pivot"
              @[SMED_PIVOT].stop="onPivot"
            />
          </div>

          <div
            :class="[
              $style.spinnerContainer,
              pageIsLoading && $style.spinnerContainer_loading,
            ]"
          >
            <svg-icon
              v-if="pageIsLoading"
              name="spinner"
              size="xl"
              :class="$style.spinner"
            />
            <slot />
          </div>
        </div>
      </div>
      <div :class="['smed-text_body-sm', $style.footer]">
        © 2018-{{ currentYear }} АО «Группа компаний «Медси». Все права
        защищены.
      </div>
    </scrollbar>

    <div id="smeda-sidebarRight-filters" :class="$style.menu" />
  </div>
</template>

<script lang="ts">
import {
  capitalize as singleCapitalize,
  computed,
  defineComponent,
  provide,
  ref,
} from 'vue';
import { useRouter } from 'vue-router';

import { useFavoriteRoutes } from '@admin/shared';
import { Avatar } from '@smartmed/ui/Avatar';
import { BackButton } from '@smartmed/ui/BackButton';
import { BaseButton } from '@smartmed/ui/BaseButton';
import { Breadcrumbs } from '@smartmed/ui/Breadcrumbs';
import { NavigationBar } from '@smartmed/ui/NavigationBar';
import { Scrollbar } from '@smartmed/ui/Scrollbar';
import { SvgIcon } from '@smartmed/ui/SvgIcon';

import { useAuth } from '@/libs/authorization/useAuth';
import { Navbar } from '@/libs/navbar';
import { useTheme } from '@/plugins/theme';
import { ROUTES } from '@/router/routes';
import { LOCAL_STORAGE_KEY } from '@/shared/const';
import { useCommon } from '@/shared/ui/FormMaker/useCommon';
import { Pivot, PIVOT_TOKEN, SMED_PIVOT } from '@/shared/ui/Pivot';

import { getUserName } from '../user';
import { useNavigationSections } from './useNavigationSections';

const capitalize = (name: string): string => {
  return name
    .split(' ')
    .map((part) => singleCapitalize(part))
    .join(' ');
};

export default defineComponent({
  name: 'LayoutDefault',
  components: {
    Avatar,
    Scrollbar,
    NavigationBar,
    Breadcrumbs,
    BaseButton,
    Navbar,
    SvgIcon,
    BackButton,
    Pivot,
  },
  setup() {
    const router = useRouter();
    const { toggleSpinner, pageIsLoading } = useCommon();
    const favoriteRoutes = useFavoriteRoutes(LOCAL_STORAGE_KEY);
    const sections = useNavigationSections();
    const { signOut: signOutFn } = useAuth();
    const pivots = ref<Record<string, boolean>>({});
    const navbarShown = ref(false);

    const theme = useTheme();

    provide(PIVOT_TOKEN, pivots);

    const currentYear = new Date().getFullYear();

    const items = computed(() => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          to: route.name,
          title: route.meta.title,
        };
      });
    });

    const filteredBreadcrumbs = computed(() =>
      items.value
        .filter(({ title }) => !!title)
        .map((item) => {
          return {
            to: { name: item.to },
            title: item.title,
          };
        })
    );

    const currentRoute = computed(() => {
      const items = filteredBreadcrumbs.value;
      const lastElement = items[items.length - 1];

      return lastElement || null;
    });

    const favoriteSelected = computed(() => {
      if (!currentRoute.value) {
        return false;
      }

      const { to, title } = currentRoute.value;

      return favoriteRoutes.isFavorite({ to, name: title });
    });

    const onFavorite = (route: { to: string; title: string }) => {
      favoriteRoutes.toggleRoute({ to: route.to, name: route.title });
    };

    const allowFavorite = computed(() => {
      return (
        router.currentRoute.value &&
        Object.keys(router.currentRoute.value.params).length === 0
      );
    });

    const signOut = () => {
      signOutFn();

      router.push({ name: ROUTES.signIn.name });
    };

    const onPivot = (
      ev: CustomEvent<{ method: 'add' | 'remove'; id: string }>
    ) => {
      const detail = ev.detail;
      const value = pivots.value;

      if (detail.method === 'remove') {
        pivots.value = {
          ...value,
          [detail.id]: false,
        };
      } else if (detail.method === 'add') {
        pivots.value = {
          ...value,
          [detail.id]: true,
        };
      }
    };

    return {
      signOut,
      sections,
      toggleSpinner,
      pageIsLoading,
      currentYear,
      filteredBreadcrumbs,
      currentRoute,
      favoriteSelected,
      onFavorite,
      allowFavorite,
      username: capitalize(getUserName() || ''),
      onPivot,
      SMED_PIVOT,
      theme,
      navbarShown,
    };
  },
});
</script>

<style lang="scss" module>
.container {
  display: flex;
}

.header {
  display: flex;
  justify-content: space-between;
}

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 280px;
  min-width: 280px;

  min-height: 100vh;
  max-height: 100%;
  border-right: 1px solid var(--smed-base-04);
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatar {
  background: linear-gradient(225deg, #33d1cc 0%, #3dabc4 100%);
  color: var(--smed-white);
}

.content {
  flex: 1;

  max-height: 100vh;
  margin-left: 280px;
  width: calc(100% - 280px);
}

.spinnerContainer {
  position: relative;

  &_loading {
    opacity: 0.64;
  }
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--smed-primary);
}

.menu {
  position: relative;
  height: 100vh;
}

.pageContent {
  --navbar-height: 0px;

  padding: 24px;
  min-height: calc(100vh - 53px - var(--navbar-height));

  &[data-navbar='true'] {
    --navbar-height: 65px;
  }
}

.title {
  display: flex;
  align-items: center;
}

.pivot {
  margin-left: auto;
  flex: 1 1 auto;
  padding-left: 0.5rem;
}

.footer {
  margin-top: auto;
  padding: 16px 32px;
  border-top: 1px solid var(--smed-base-04);
  box-shadow: 0px -3px 16px 0px #0000000f;
}
</style>
