import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const distributionRoutes: RouteRecordRaw = {
  path: 'distribution',
  name: ROUTES.salesToolsDistribution.name,
  redirect: { name: 'salesToolsDistributionList' },
  meta: {
    title: ROUTES.salesToolsDistribution.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: 'salesToolsDistributionList',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.salesToolsDistributionCreate.name,
      component: () => import('./Create.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsDistributionCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.salesToolsDistributionEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsDistributionEdit.title,
        role: [],
      },
    },
    {
      path: 'csv',
      name: ROUTES.salesToolsDistributionCsv.name,
      component: () => import('./AddCsv.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsDistributionCsv.title,
        role: [],
      },
    },
    {
      path: 'reset',
      name: ROUTES.salesToolsDistributionResetStatuses.name,
      component: () => import('./ResetStatuses.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.salesToolsDistributionResetStatuses.title,
        role: [],
      },
    },
  ],
};
