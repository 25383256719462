import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const nonMedicalProgramsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'non-medical-programs',
    name: ROUTES.nonMedicalPrograms.name,
    meta: {
      title: ROUTES.nonMedicalPrograms.title,
      role: [roles.MarketplaceAdmin],
    },
    redirect: { name: 'NonMedicalProgramsRoot' },
    component: () => import('@/libs/layouts/Simple.vue'),

    children: [
      {
        path: '',
        name: 'NonMedicalProgramsRoot',
        meta: {
          role: [],
        },
        component: () => import('./NonMedicalPrograms.vue'),
      },
      {
        path: 'add',
        name: ROUTES.addNonMedicalProgram.name,
        meta: {
          title: ROUTES.addNonMedicalProgram.title,
          role: [],
        },
        component: () => import('./NonMedicalProgram.vue'),
      },
      {
        path: ':id',
        name: ROUTES.editNonMedicalProgram.name,
        meta: {
          title: ROUTES.editNonMedicalProgram.title,
          role: [],
        },
        props: (route) => ({ id: route.params.id }),
        component: () => import('./NonMedicalProgram.vue'),
      },
    ],
  },
];
