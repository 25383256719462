import { AnyObject } from '@admin/shared';

import { Connection } from './Connection.interface';

export class Http {
  constructor(private client: Connection, private endpoint: string) {}

  private getUrl(url: string) {
    return url.startsWith('/') ? `${this.endpoint}${url}` : url;
  }

  get(url: string, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'get',
      config,
    });
  }

  post(url: string, data?: any, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'post',
      data,
      config,
    });
  }

  put(url: string, data?: any, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'put',
      data,
      config,
    });
  }

  patch(url: string, data?: any, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'patch',
      data,
      config,
    });
  }

  delete(url: string, data?: any, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'delete',
      data,
      config,
    });
  }

  options(url: string, config?: AnyObject) {
    return this.client.request({
      url: this.getUrl(url),
      method: 'options',
      config,
    });
  }
}
