import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const quizRoutes: RouteRecordRaw = {
  path: 'quiz',
  name: ROUTES.chroniclesQuiz.name,
  redirect: { name: ROUTES.chroniclesQuizList.name },
  meta: {
    title: ROUTES.chroniclesQuiz.title,
    role: [],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [
    {
      path: '',
      name: ROUTES.chroniclesQuizList.name,
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: 'create',
      name: ROUTES.chroniclesQuizCreate.name,
      component: () => import('./Create.vue'),
      meta: {
        title: ROUTES.chroniclesQuizCreate.title,
        role: [],
      },
    },
    {
      path: ':id',
      name: ROUTES.chroniclesQuizPreview.name,
      component: () => import('./Preview.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.chroniclesQuizPreview.title,
        role: [],
      },
    },
    {
      path: ':id/edit',
      name: ROUTES.chroniclesQuizEdit.name,
      component: () => import('./Edit.vue'),
      props: (route) => ({ id: route.params.id }),
      meta: {
        title: ROUTES.chroniclesQuizEdit.title,
        role: [],
      },
    },
  ],
};
