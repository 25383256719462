import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { useAuth } from '@/libs/authorization/useAuth';
import { ROUTES } from '@/router/routes';

export function routerLoginGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  const { isAuth } = useAuth();

  if (isAuth()) {
    next({
      name: ROUTES.home.name,
    });
  } else {
    next();
  }
}
