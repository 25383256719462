import { RouteRecordRaw } from 'vue-router';

import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

export const medicalProgramsRoutes: ReadonlyArray<RouteRecordRaw> = [
  {
    path: 'medical-programs',
    name: ROUTES.medicalPrograms.name,
    meta: {
      title: ROUTES.medicalPrograms.title,
      role: [roles.MarketplaceAdmin],
    },
    redirect: { name: 'MedicalProgramsRoot' },
    component: () => import('@/libs/layouts/Simple.vue'),

    children: [
      {
        path: '',
        name: 'MedicalProgramsRoot',
        meta: {
          role: [],
        },
        component: () => import('./MedicalPrograms.vue'),
      },
      {
        path: 'add',
        name: ROUTES.addMedicalProgram.name,
        meta: {
          title: ROUTES.addMedicalProgram.title,
          role: [],
        },
        component: () => import('./MedicalProgram.vue'),
      },
      {
        path: ':id',
        name: ROUTES.editMedicalProgram.name,
        meta: {
          title: ROUTES.editMedicalProgram.title,
          role: [],
        },
        props: (route) => ({ id: route.params.id }),
        component: () => import('./MedicalProgram.vue'),
      },
    ],
  },
];
