import { generateUUID } from '../string/generateUUID';

export function memo<T extends (...args: any) => any>(fn: T) {
  const uuid = generateUUID();
  const cache = new Map();

  return (...args: Parameters<T>) => {
    const key = `${uuid}_${JSON.stringify(args)}`;

    if (cache.has(key)) {
      return cache.get(key);
    }

    const result = fn(...(args as any));

    cache.set(key, result);

    return result;
  };
}
