export const receptionsSettings = {
  receptionsSettings: {
    title: 'Настройки сервиса',
    name: 'ReceptionsSettings',
  },
  cacheErase: {
    name: 'CacheErase',
    title: 'Сброс кэша',
  },
} as const;
