import { RouteRecordRaw } from 'vue-router';

import { ROUTES } from '@/router/routes';

export const paymentsOrdersRoutes: RouteRecordRaw = {
  path: 'orders',
  name: ROUTES.paymentsOrders.name,
  meta: {
    title: ROUTES.paymentsOrders.title,
    role: [],
  },
  redirect: { name: 'PaymentsOrdersRoot' },
  component: () => import('@/libs/layouts/Simple.vue'),
  children: [
    {
      path: '',
      name: 'PaymentsOrdersRoot',
      meta: {
        role: [],
      },
      component: () => import('./List.vue'),
    },
    {
      path: ':id',
      name: ROUTES.paymentsOrderPreview.name,
      meta: {
        role: [],
      },
      component: () => import('./Preview.vue'),
      props: (route) => ({ id: Number(route.params.id) }),
    },
  ],
};
