import { RouteRecordRaw } from 'vue-router';

import { Role } from '@/libs/user/role/roles';

type RouteNameRolesDict = Record<string, Role[]>;

function deep(
  route: RouteRecordRaw,
  parentRoles: Role[] = []
): RouteNameRolesDict {
  const result: RouteNameRolesDict = {};
  const name = route.name as string | undefined;
  const role = (route.meta?.role || []) as Role[];

  if (!name) {
    throw new Error(`У роута ${route.path} нет имени`);
  }

  const currentRoles = parentRoles.concat(role);

  result[name] = currentRoles;

  if (route.children) {
    route.children.forEach((child) => {
      const childResult = deep(child, currentRoles);

      Object.keys(childResult).forEach((key) => {
        result[key] = childResult[key];
      });
    });
  }

  return result;
}

export function getRouteNameRolesDict(
  routes: ReadonlyArray<RouteRecordRaw>
): RouteNameRolesDict {
  return routes.reduce((result, route) => {
    const routeResult = deep(route);

    return { ...result, ...routeResult };
  }, {} as RouteNameRolesDict);
}
