import { roles } from '@/libs/user/role/roles';
import { ROUTES } from '@/router/routes';

import { cacheEraseRoutes } from './views/CacheErase/routes';

export const receptionsSettingsRoutes = {
  path: 'settings',
  name: ROUTES.receptionsSettings.name,
  redirect: { name: cacheEraseRoutes.name },
  meta: {
    title: ROUTES.receptionsSettings.title,
    role: [roles.ReceptionAdmin],
  },
  component: () => import('@/libs/layouts/Simple.vue'),

  children: [cacheEraseRoutes],
};
